<template>
  <div class="container-fluid d-flex justify-content-center align-items-center" style="margin-top: -0%">
    <div class="col-md-4 p-4 rounde bg-white shadow "  >
      <div class="position-absolute">
        <img class="dame" src="../../assets/img/dame_en_veste_jaune.svg" style="
          margin-top: -60%;
          margin-left: -160%;
            top: 652px;
            left: 247px;
            width: 274px;
            height: 268px;
            padding-left: 65%"
             alt="image du globe"/>
      </div>
      <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS" class="img-fluid"
           style="max-width: 60px; height: auto;margin-top: -3%;">
      <span class="col-sm-5" style="color: #0D5286; margin-left: 0; margin-top: 0; font-size: 200%"
      ><b>SYGALIN SAS</b>
        </span>
      <br/>
      <br/>
      <div class="small">
        <h4 class="mb-4" style="color: #0C5286"><b>Créez un mot de passse.</b></h4>
        <p class="MTNBrighterSans-Bold-p" style="margin-top: -26px; ">Confirmer votre nouveau mot de passe!</p>
      </div>
      <br/>
      <form >
        <div class="mb-2 row align-items-center d-flex justify-content-center">
          <div class="col-sm-9 ">
            <test-password id="password1"/>
          </div>
        </div>
      </form>
    </div>
    <div class=" col-sm-5 justify-content-end d-sm-none d-md-block d-lg-block"
         style="padding-left: 10%; margin-left: 5%">
      <img class="" src="../../assets/img/securité%20dame.svg" style="background-color: transparent;
         max-width: 500px;'' height: auto;
            padding-top: 50%"
           alt="Dame de sécurité"/>
    </div>
  </div>
</template>


<script>
import TestPassword from "@/view/signup/passwordmodal.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Password',
  components: {
    TestPassword
  },
  data() {
    return {
      password1: '',
      password2: '',
      passwordErrorL: true,
      passwordErrorUp: true,
      passwordErrorMin: true,
      passwordError: true,
      isEmpty: false,
      isEmpty2: false,
      showPassword1: false,
      showPassword2: false,
      IsConform: true
    };
  },
  methods: {
    togglePasswordVisibility2() {
      this.showPassword2 = !this.showPassword2;
      const passwordInput = document.getElementById('password2');
      passwordInput.type = this.showPassword2 ? 'text' : 'password';
    },
    submit()
    {
      if (this.password1 === ''){
        this.isEmpty = true;
      }else if (this.password2 === ''){
        this.isEmpty2 = true;
      }else if(this.passwordError !== false )
      {
        // this.$router.push('/success_create');
      }
    }
  }
};
</script>

<style>
.green-text {
  color: green;
}
.password-input input.show-password {
  -webkit-text-security: none;
  text-security: none;
}

.password-input .toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.MTNBrighterSans-Bold-p {
  font-family: MTNBrighterSans,sans-serif;
  font-size: 16px;
  color: #C2C7D1;
  margin-top: -30px;
}
.MTNBrighterSans-Bold-Sygalin {
  font-family: MTNBrighterSans, sans-serif;
  font-size: 11px;
  color: #0E5386;
}
.placeholder-bold::placeholder {
  font-weight: bold;
  color: #a3a9b7;
}

.router-link {
  text-decoration: none;
}
</style>
