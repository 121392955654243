<template>
  <div class="container-fluid d-flex justify-content-center align-items-center" style="margin-top: -0%">
    <div class="col-md-4 p-4 rounde bg-white shadow "  >
      <div class="position-absolute">
        <img class="" src="../../../assets/img/dame_en_veste_jaune.svg" style="
          margin-top: -60%;
          margin-left: -160%;
            top: 652px;
            left: 247px;
            width: 274px;
            height: 268px;
            padding-left: 65%"
             alt="image du globe"/>
      </div>
      <img src="../../../assets/img/logo.png" alt="logo SYGALIN SAS" class="img-fluid"
           style="max-width: 60px; height: auto;margin-top: -3%;">
      <span class="col-sm-5" style="color: #0D5286; margin-left: -2%; margin-top: -0%; font-size: 200%"
      ><b>SYGALIN SAS</b>
        </span>
      <br/>
      <br/>
      <div class="small">
        <h4 class="mb-4" style="color: #0C5286"><b>Nouveau mot de passse.</b></h4>
        <p class="font-brand" style="margin-top: -26px; ">Confirmer votre nouveau mot de passe!</p>
      </div>
      <br/>
      <form @submit.prevent="submit">
        <div class="mb-2 row align-items-center d-flex justify-content-center">
          <div class="col-sm-9 ">
            <input placeholder="Nouveau mot de passe"  @input="ValidePassword"
                   :type="showPassword1 ? 'text' : 'password'"
                   class="form-control placeholder-bold rounded-pill" id="password1" v-model="password1"
                   style="background-color: #F6F8FA;"/>
            <div class="">

            <n  @click="togglePasswordVisibility1" style="cursor: pointer;">
        <n v-if="showPassword1" class="far position-absolute fa-solid fa-eye" style=" padding-left: 8%; margin-top: -2%"></n>
        <n v-else class="far fa-solid position-absolute fa-eye-slash" style=" padding-left: 8%; margin-top: -2%"> </n>
      </n>
            </div>
            <n v-if="usernameError" class="text-danger">Le champ mot de passe est requis.</n><br/>
            <input v-if="ValidePassword" placeholder="Confirmer" @input="ConformPassword"
                   :type="showPassword2 ? 'text' : 'password'"
                   class="form-control placeholder-bold rounded-pill" id="password2" v-model="password2"
                   style="background-color: #F6F8FA;"/>
            <div class="" >
            <n  @click="togglePasswordVisibility2" style="cursor: pointer;">
        <i v-if="showPassword2" class=" position-absolute far fa-solid fa-eye" style=" padding-left: 8%; margin-top: -2%"></i>
        <i v-else class="far fa-solid position-absolute fa-eye-slash" style=" padding-left: 8%; margin-top: -2%" > </i>
      </n>
              <br/>
            </div>
            <div class="col-sm-12">
              <span v-if="isEmpty" class="text-danger text-start" style="margin-left: 10px">Le champ mot de passe est requis.</span>
              <p v-if="!passwordErrorL"  class="text-danger">
                Le mot de passe doit avoir au moins 08 caractères
              </p>
              <div style="margin-top: -10px">
                <p v-if="!passwordErrorMin" class="text-danger">
                  Le mot de passe doit avoir au moins un caractère Minuscule
                </p>
              </div>
              <p v-if="!passwordErrorUp" class="text-danger">
                Le mot de passe doit avoir au moins une Majuscule
              </p>
              <p v-if="!passwordError" class="text-danger">
                Le mot de passe doit avoir au moins un caractère Spécial
              </p>
              <p v-if="!IsConform" class="text-danger">
                <br/>
                Les mots de passe sont différents
              </p>
            </div>
            <div class="justify-content-start d-flex col-sm-12">
              <span v-if="isEmpty2" class="text-danger">Le champ mot de passe est requis.</span>
            </div>
            <div class="col-sm-9">
              <span v-if="isEmpty" class="text-danger text-start" style="margin-left: 8px">Le champ mot de passe est requis.</span>
            </div>
          </div>
          <div class="col-md-11 d-flex justify-content-end">
            <button type="submit" class="my-button">
              <span v-if="!isSubmitting" id="button-text">Valider</span>
              <div v-else class="spinner" id="spinner"></div>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class=" col-sm-5 justify-content-end d-sm-none d-md-block d-lg-block"
         style="padding-left: 10%; margin-left: 5%">
      <img class="" src="../../../assets/img/securité%20dame.svg" style="background-color: transparent;
         max-width: 500px;'' height: auto;
            padding-top: 50%"
           alt="Dame de sécurité"/>
    </div>
  </div>
</template>


<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'New',
  components: {
  },
  data() {
    return {
      username: localStorage.getItem('username'),
      password1: '',
      password2: '',
      passwordErrorL: true,
      passwordErrorUp: true,
      passwordErrorMin: true,
      passwordError: true,
      isEmpty: false,
      isEmpty2: false,
      showPassword1: false,
      showPassword2: false,
      IsConform: false
    };
  },
  methods: {
    togglePasswordVisibility1() {
      this.showPassword1 = !this.showPassword1;
      const passwordInput = document.getElementById('password1');
      passwordInput.type = this.showPassword1 ? 'text' : 'password';
    },
    togglePasswordVisibility2() {
      this.showPassword2 = !this.showPassword2;
      const passwordInput = document.getElementById('password2');
      passwordInput.type = this.showPassword2 ? 'text' : 'password';
    },
    ValidePassword()
    {
      const regexLongueur = /.{8,}/; // Au moins 8 caractères
      const regexMajuscule = /[A-Z]/; // Au moins une majuscule
      const regexMinuscule = /[a-z]/; // Au moins une minuscule
      const regexSpecial = /[0-9]/;
      this.isEmpty = false;
      if(!regexLongueur.test(this.password1)){
        this.passwordErrorL = false
      }else{
        this.passwordErrorL = true;
        // setTimeout(
        // this.passwordErrorL = 'ok',
        // 300)
      }
      if(!regexMajuscule.test(this.password1)) {
        this.passwordErrorUp = false
      }else {
        this.passwordErrorUp = true
      }
      if(!regexMinuscule.test(this.password1))
      {
        this.passwordErrorMin = false
      }else {
        this.passwordErrorMin = true
      }
      if(!regexSpecial.test(this.password1)){
        this.passwordError = false
      }else {
        this.passwordError = true
      }

    },
    ConformPassword(){
      if (this.password1 === this.password2){
        this.IsConform = true
      } else {
        this.IsConform = false
      }
    },
    submit()
    {
      if (this.password1 === ''){
        this.isEmpty = true;
      }else if (this.password2 === ''){
        this.isEmpty2 = true;
      }else if(this.passwordError !== false )
      {
        this.$router.push('/complet');
      }
    }
  }
};
</script>

<style>
.green-text {
  color: green;
}
.password-input input.show-password {
  -webkit-text-security: none;
  text-security: none;
}

.password-input .toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.MTNBrighterSans-Bold-p {
  font-family: MTNBrighterSans,sans-serif;
  font-size: 16px;
  color: #C2C7D1;
  margin-top: -30px;
}
.MTNBrighterSans-Bold-Sygalin {
  font-family: MTNBrighterSans, sans-serif;
  font-size: 11px;
  color: #0E5386;
}
.placeholder-bold::placeholder {
  font-weight: bold;
  color: #a3a9b7;
}

.router-link {
  text-decoration: none;
}
</style>
