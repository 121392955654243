<template class="screen">
  <test class="side"/>
    <user-navbar class="sideNav"/>
    <sidebar class="sideNav"/>
  <div class="container-fluid col-sm-10 align-item-start">
    <div class=" " style="margin: 0 0 4% 0">
      <h1 class="font-brand" style="color: #0D5286">Bienvenue Ibrahim</h1>
      <h5 class="" style="font-family: Raleway, regular!important; color: #8A92A6 "
      >Gérez vos informations, ainsi que la confidentialité <br/>et la sécurité de vos données pour profiter au mieux des services <b>SYGALIN</b></h5>
    </div>
    <div class="col-sm-6" style="margin-left: 0">
      <div class="card bg-white rounded-4">
      <div class="card-body">
        <div class="row col-sm-12">
          <div class="col-sm-7 text-start">
            <p class="font-brand" style="font-size: 20px">Votre compte <br/> est sécurisé</p>
          </div>
          <div class="col-sm-5 align-items-end  position-absolute " style="
            padding-top: 0;
            width: 43%;
            height: auto;
            margin-top: 0;
            margin-left: 57%">
              <img src="../../assets/img/shield-checkmark.svg" class="position-relative"
                   style="
                   margin-left: 0;
                   max-width: 80%;
                   height: auto;"/>
        </div>
        </div>
        <div class="text-start  col-sm-8">
          <p style="font-size: 12px; font-family: Raleway, regular!important;"
          >Le Check-Up a vérifié votre compte et vous avez Configuré la protection de votre compte.</p>
        </div>
      </div>
        <div class="card-footer d-flex align-items-center" style="padding-top: -10%">
          <router-link to="/security" class="router-link">
            <p class="" style="font-family: Raleway, regular!important;
          font-size: 20px;
          color: #1062E5;">Afficher les détails</p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="d-flex col-sm-12 align-items-end hide-img " style="margin-top: 20%">
        <img src="../../assets/img/porte_ouverte.svg" class="position-absolute"
             style="margin-left: 45%;
                    width: auto;
                    max-height: 70%;
                    margin-top: 100%;"
             alt="dame qui souhaite la bienvenue"/>
    </div>
  </div>
  <Footer/>
  <floating-botton/>
</template>

<script>


import UserNavbar from "@/components/UserNavbar.vue";
import Sidebar from "@/components/SideBar.vue";
import Footer from "@/components/Footer.vue";
import FloatingBotton from "@/components/floatingBotton.vue";
import Test from "@/view/signup/responsiveBar.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "UserDashboard",
  components: {
    Test,
    FloatingBotton,
    Footer,
    Sidebar,
    UserNavbar,
  },
  data() {
    return {
      isHover: false,
      test: false,
    }
  },
methods: {
  },
}
</script>

<style scoped>
.bottom-div {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f0f0f0;
  transition: height 0.5s;
  overflow: hidden;
}
@media screen and (max-width: 1278px) {
  .sideNav{
    display: none !important;
    max-width: 0!important;
  }
  .hide-img{
    display: none !important;
    max-width: 0!important;
  }
  body{
    padding-left: 0 !important;
  }
}
.expand {
  height: 100px;
}

</style>