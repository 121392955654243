<template>
  <div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounded-5 shadow bg-white" style="margin-top: 0%" >
      <img src="../../../assets/img/logo.png" alt="logo SYGALIN SAS"
           class="img-fluid " style="max-width: 60px;'' height: auto;margin-top: -4%; ">
      <span class="mb-3 position-relative" style="">
          <b style="font-family: MTNBrighterSans-Bold, sans-serif; font-size: 25px;
              margin-left: -2%;color: #0D5286">SYGALIN SAS</b>
        </span>
      <div class="position-relative">
        <div class="col-7 position-absolute">
          <img class="img-fluid" src="../../../assets/img/dame_en_veste_jaune.svg"
               style="width: 60%;
                 height: auto;
                 margin-left: -90%;
                 margin-top: -90%"
               alt="La dame en qui manipule son pc"/>
        </div>
      </div>
      <br/>
      <div class="small">
        <h5 class="mb-3" style="color: #0D5286"><b>Un message a été envoyé <br>Au numéro renseigné.</b></h5>
        <p class="MTNBrighterSans-Bold-ligh" style="margin-top: -18px"
        >Saisissez le code qui vous a été envoyé!</p><br/>
      </div>
        <form @submit.prevent="handleSubmit" >
          <div class="d-flex justify-content-center ">
         <div class="row col-md-10 text-center">
           <v-otp-input
             input-classes="otp-input borde"
             separator=""
             :num-inputs="6"
             :should-auto-focus="true"
             :is-input-num="true"
             id="otp"
             @on-change="handleOnChange"
             @on-complete="handleOnComplete"
             v-model="otpValue"
         />
           <div class="text-start">
             <br/>
             <span style=" font-family: MTNBrighterSans, sans-serif; font-size: 15px;
          color: #6B982F" >
            <router-link to="#" class="router-link text-success"
            ><b>Renvoyer le code! </b></router-link> </span>
             <span v-if="countdown > 0" style="font-family: MTNBrighterSans, sans-serif; color: #134973; font-size: 15px"><b> {{ countdown }}</b></span>
           </div>
         </div>
      </div>
      <br/><br/>
      <br/><br/>
      <br/><br/>
            <div class="col-4 position-relative">
              <div class="position-absolute">
                <img class="" src="../../../assets/img/Jolie_dame_assise.svg" style="
                margin-top: -100%;
                margin-left: 200%;
                width: 100%;
                height: auto;"
                alt="Dame assise sur sa table"/>
              </div>
            </div>
      <div class="d-flex justify-content-center">
        <button type="submit" class="my-button">
          <span v-if="!isSubmitting" id="button-text">Valider</span>
          <div v-else class="spinner" id="spinner"></div>
        </button>
      </div>
      </form>
      </div>
    <div class=" col-sm-5 d-sm-none d-md-block d-lg-block" style="padding-left: 1%; margin-left: 5%">
      <img class="" src="../../../assets/img/securité%20dame.svg" style="background-color: transparent;
         max-width: 500px;'' height: auto;
            padding-top: 20%"
           alt="image de la dame cadenas"/>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "number",
  data(){
    return{
      otpValues: ['', '', '', '', '', ''],
      countdown: 7,
      isNull: false,
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(this.timer);
      }
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods:{
    handleSubmit(){
      this.$router.push('/complet')
    }
  }
}
</script>

<style scoped>
.borde {
  margin-left: 5px;
  width: 300%;
  height: 300%;
  border: 3px solid #0D5286;

}

</style>