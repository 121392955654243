<template>
  <div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounded-5 bg-white shadow responsive">
      <div class="col-sm-12">
        <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS" class="img-fluid"
             style="max-width: 60px; height: auto; margin-top: -3%;">
        <span class="col-sm-5 font-brand" style="color: #0D5286; margin-left: 0;  font-size: 200%"
        ><b>SYGALIN SAS</b></span>
        <div class="position-relative">
          <div class="col-7">
            <img  class="img-fluid dame" src="../../assets/img/femme_violette.svg"
                  style="width: 60%;
                 height: auto;
                 margin-left: 125%;
                 margin-top: -60%"
                 alt="La dame en qui manipule son pc"/>
          </div>
        </div>
      </div>
      <div class="small">
        <h4 class="mb-4" style="color: #0D5286" ><b>Créer un compte!</b></h4>
        <p class="MTNBrighterSans-Bold-ligh" style="margin-top: -25px">Veuillez renseigner votre nom et prénom!</p>
      </div>
      <br/>
      <form @submit.prevent="handleSubmit">
        <div class="mb-2 row align-items-center d-flex justify-content-center">
          <div class="col-sm-9">
            <input type="text" placeholder="Insérez votre nom" :disabled="isSubmitting"
                   class="form-control placeholder-bold rounded-pill" id="name" v-model="first_name"
                   style="background-color: #F6F8FA;" required />
            <span v-if="first_nameError" class="text-danger" style="margin-top: -20px">Le nom est requis.</span>
            <p></p>
            <input type="text" placeholder="Insérez votre prénom"
                   class="form-control rounded-pill placeholder-bold" id="firstname" v-model="last_name"
                   style="background-color: #F6F8FA;" required :disabled="isSubmitting" />
            <span v-if="last_nameError" class="text-danger">Le prénom est requis.</span>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div class="position-absolute">
          <img class="dame" src="../../assets/img/homme_gros.svg" style="
          margin-top: -64%;
          margin-left: -105%;
            top: 652px;
            left: 247px;
            width: 274px;
            height: 268px;
            padding-top: 50%"
               alt="image du globe"/>
        </div>
        <div class="col-md-12 d-flex justify-content-end">
          <button type="submit" class="my-button">
            <span v-if="!isSubmitting" id="button-text">Suivant</span>
            <div v-else class="spinner" id="spinner"></div>
          </button>
        </div>
      </form>
    </div>
    <div class="img-fluid col-sm-5 d-none d-sm-none d-md-block d-lg-block">
      <img class="img-fluid" src="../../assets/img/Globe.svg"
           style="max-width: 100%; height: auto;  margin-left: 50px;" alt="image du globe"/>
    </div>
  </div>
</template>


<script>
import { useRoute } from 'vue-router';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "signup",
  components: {

  },
  data() {
    return {
      isLoading:false,
      first_name: '',
      last_name: '',
      isSubmitting:false,
      first_nameError: false,
      last_nameError: false,
      netError: false
    };
  },
  created() {
    const first_name = localStorage.getItem('first_name');
    const last_name = localStorage.getItem('last_name');
     if (first_name && last_name ) {
      this.first_name = first_name;
      this.last_name = last_name;
    }
  },
  methods: {
    async handleSubmit() {
      if (!this.first_name.trim()) {
        this.nameError = true;
      }
      else if (!this.first_name.trim()) {
        this.first_nameError = true
      }else {
        this.isSubmitting = true;
        localStorage.setItem('first_name', this.first_name);
        localStorage.setItem('last_name', this.last_name);
        const route = useRoute()
        console.log("queries: ", this.$route.query)
        const queryStrings = new URLSearchParams(this.$route.query).toString()
        localStorage.setItem('params', queryStrings )
        setTimeout(() => {
          this.isSubmitting = true;
          this.$router.push('/login_chose');
        }, 7000);
      }
    }
  }
};
</script>

<style>

.MTNBrighterSans-Bold-Sygalin {
  font-family: "MTNBrighterSans", sans-serif;
  font-size: 12px;
  color: #0D5286;
}

.my-button:hover {
  background-color: #0D5286;
}

.MTNBrighterSans-Bold-Sygalin {
  font-family: "MTNBrighterSans", sans-serif;
  font-size: 11px;
  color: #0D5286;
}
.MTNBrighterSans-Bold-ligh {
  font-family: "MTNBrighterSans", sans-serif;
  font-size: 12px;
  color: #B1B7C4;
}
.placeholder-bold::placeholder {
  font-weight: bold;
  color: #0D5286;
}
@media screen and (max-width: 1278px) {
  .sideNav{
    display: none !important;
    max-width: 0!important;
  }
}

</style>
