<template class="screen">
  <test class="side"/>
  <user-navbar class="sideNav"/>
  <sidebar class="sideNav"/>
  <div class="container-fluid col-sm-10 align-item-start" >
    <div class="text-start ">
      <h1 class="text-start font-brand" style="color: #0D5286">Périphériques</h1>
      <h5 class="" style="font-family: Raleway, regular!important; color: #8A92A6; margin: 0% 0% 5% 0% "
      >Examinez tous les appareils avec lesquels vous vous êtes connecté!</h5>
    </div>
    <div class="row col-sm-12">
      <div class="col-sm-12" style="margin-left: 0">
        <div class="card bg-white rounded-4">
          <div class="card-body">
            <div class="row col-sm-12">
              <div class="col-sm-12 text-center">
                <p style="font-size: 20px;
                    color: #0D5286 " class="font-brand">Vos appareils</p>
              </div>
              <table style="margin: 0% 0 0 03%" class="table table-striped">
                <thead>
                <tr>
                  <th class="text-start" style="font-family: Raleway, regular; font-size: 14px;">
                    <b> Appareils </b>
                  </th>
                  <th class="text-start" style="font-family: Raleway, regular; font-size: 14px;">
                    <b>Date</b>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr style="margin: 0% 0 0 03%" >
                  <th class="text-start" scope="row" style="font-family: Raleway, regular; font-size: 14px;"
                  >Oppo Reno 8 lite<b>{{}}</b></th>
                  <th class="text-start" scope="row" style="font-family: Raleway, regular; font-size: 14px;"
                  >12 Mars 2024 à 10H <b>{{}}</b>
                    <img src="../../assets/img/itemsSidebar/supprimer.svg"
                         class="position-absolute" style="height: auto;
                   background-color: transparent;
                   margin: -5% 0 0 20% ;
                   width: 70px "/>
                  </th>
                </tr>
                <tr style="margin: 8% 0 0 03%">
                  <th class="text-start" style="
                  font-family: Raleway, regular; font-size: 14px;">
                    Huawei P20 Lite<b>{{}}</b>
                  </th>
                  <th class="text-start" style="
                  font-family: Raleway, regular; font-size: 14px;">
                    18 Mars 2024 à 10H <b>{{}}</b>
                    <img src="../../assets/img/itemsSidebar/supprimer.svg"
                         class="position-absolute" style="height: auto;
                   background-color: transparent;
                   margin: -5% 0 0 20% ;
                   width: 70px "/>
                  </th>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
  <floating-botton/>
<!--  <div style="margin: 27% 0 0 -2%">-->
<!--  </div>-->
</template>

<script>
import UserNavbar from "@/components/UserNavbar.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/SideBar.vue";
import FloatingBotton from "@/components/floatingBotton.vue";
import Test from "@/view/signup/responsiveBar.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "devices",
  components: {
    Test,
    FloatingBotton,
    Footer,
    Sidebar,
    UserNavbar
  }
}
</script>

<style >
@media screen and (max-width: 1278px) {
  .sideNav{
    display: none !important;
    max-width: 0!important;
  }
  .hide-img{
    display: none !important;
    max-width: 0!important;
  }
  body{
    padding-left: 0 !important;
  }
}
</style>