<template>
  <div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounde shadow bg-white">
      <div class="position-absolute">
        <img class="" src="../../../assets/img/dame_en_veste_jaune.svg" style="
          margin-top: -60%;
          margin-left: -160%;
            top: 652px;
            left: 247px;
            width: 274px;
            height: 268px;
            padding-left: 65%"
             alt="image du globe"/>
      </div>
      <img src="../../../assets/img/logo.png" alt="logo SYGALIN SAS" class="img-fluid"
           style="max-width: 60px; height: auto; margin-top: -2%">
      <span class="col-sm-5" style="color: #0D5286; margin-left: -2%; margin-top: 3%; font-size: 200%"
      ><b>SYGALIN SAS</b></span>
      <br/>
      <br/>
      <br/>
      <div class="small">
        <h5 class="mb-4" style="color: #0C5286;
        font-size: 26px;"><b>Récupération de compte.</b></h5>
        <p class="MTNBrighterSans-Bold-ligh" style="margin-top: -18px"
        >Veuillez confirmer que jo***********om est<br/>Votre compte!</p>
      </div>
      <br/>
      <form @submit.prevent="handleSubmit">
        <div class="row" style="margin-left: 10%">
          <div class="col-sm-10 justify-content-center">
          <input type="email" placeholder="Jo*************om"
                 class="form-control placeholder-bold rounded-pill"  id="username" v-model="username"
                 style="background-color: #F6F8FA;"/>
        </div>
          <br/>
          <br/>
          <div class="d-flex justify-content-start">
            <router-link to="/other_method" class="router-link">
              <span style="color: #B5CB97; font-size: 13px; font-family: Raleway, regular"
              >Essayer une autre méthode!</span>
            </router-link>
          </div>
        </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
        <div class="position-absolute">
          <img class="" src="../../../assets/img/Monsieur%20assis%20sur%20la%20chaise%20jaune.svg" style="
          margin-top: -73%;
          margin-left: -90%;
            top: 652px;
            left: 247px;
            width: 274px;
            height: 268px;
            padding-top: 50%"
               alt="image du globe"/>
        </div>
        <div class="col-md-12 d-flex justify-content-end">
          <button type="submit" class="my-button">
            <span v-if="!isSubmitting" id="button-text">Suivant</span>
            <div v-else class="spinner" id="spinner"></div>
          </button>
        </div>
      </form>
    </div>
    <div class="img-fluid col-sm-5">
      <img class="" src="../../../assets/img/ordi%20tel.svg" style="background-color: transparent;
         max-width: 700px;'' height: auto; margin-left: 60px"
           alt="image du globe"/>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'recovery',
  data() {
    return {};
  },
  methods: {
    handleSubmit() {
      this.$router.push('/recover')
      console.log('test');
    }
  }
}
</script>

<style>
.my-button {
  background-color: #0056b3;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 15px;
}
.router-link {
  text-decoration: none;
}
</style>
