import { createRouter, createWebHistory } from 'vue-router';

import Signin from "@/view/signin/SignIn.vue";
import SignOn from "@/view/signin/SignOn.vue";
import auth from "@/view/signin/auth.vue";
import signUp from "@/view/signup/SignUp.vue";
import next from "@/view/signup/Next.vue";
import generalData from "@/view/signup/GeneralData.vue";
import method from "@/view/signup/Method.vue";
import recovery from "@/view/signin/recover/Recovery.vue";
import New from "@/view/signin/recover/New.vue";
import other from "@/view/signin/recover/Other.vue";
import complet from "@/view/signin/recover/complet.vue";
import success from "@/view/signup/success.vue";
import UserDashboard from "@/view/UserPages/UserDashboard.vue";
import Data from "@/view/UserPages/Data.vue";
import password from "@/view/signup/Password.vue";
import Accountcomplet from "@/view/signup/Accountcomplet.vue";
import number from "@/view/signin/recover/number.vue";
import OtpSms from "@/view/signup/OTP/OtpSms.vue";
import OtpQr from "@/view/signup/OTP/OtpQr.vue";
import OtpApk from "@/view/signup/OTP/OtpApk.vue";
import qrScan from "@/view/signup/OTP/QrScan.vue";
import completedConfig from "@/view/signup/completedConfig.vue";
import test from "@/view/signup/responsiveBar.vue";
import marketPlace from "@/view/MarketPlace.vue";
import settings from "@/view/signup/settings.vue";
import history from "@/view/UserPages/history.vue";
import devices from "@/view/UserPages/devices.vue";
import services from "@/view/UserPages/services.vue";
import security from "@/view/UserPages/security.vue";
import edit from "@/view/UserPages/edit.vue";
import testmodal from "@/view/signup/update.vue";
import notFound from "@/view/NotFound.vue";
import encore from "@/view/signin/error.vue";
const routes = [
    {
        path: '/',
        redirect: '/signin'
    },
    {
        path: '/dashboard',
        name: 'UserDashboard',
        component: UserDashboard,
        meta: { title: 'Accueil' }
    },
    {
        path: '/marketplace',
        name : 'marketplace',
        component: marketPlace,
        meta: { title: 'SYGALIN Marketplace' }
    },
    {
        path: '/otpqr',
        name : 'otpqr',
        component: OtpQr,
        meta: { title: 'Configuration' }
    },
    {
        path: '/error',
        name : 'encore',
        component: encore,
        meta: { title: 'test' }
    },
    {
        path: '/qrinfo',
        name : 'qrinfo',
        component: OtpApk,
        meta: { title: 'Configuration' }
    },
    {
        path: '/scan',
        name : 'scan',
        component: qrScan,
        meta: { title: 'Configuration' }
    },
    {
        path: '/completed',
        name : 'completed',
        component: completedConfig,
        meta: { title: 'Configuration ' }
    },
    {
        path: '/password',
        name : 'password',
        component: password,
        meta: { title: 'Mot de passe' }
    },
    {
        path: '/SignIn',
        name: 'SignIn',
        component: Signin,
        meta: { title: 'Connexion' }
        // props: (route) => ({ signOnComponent: route.query.signOnComponent })
    },
    {
        // path: '/signon/:queryString',
        path: '/signon',
        name: 'SignOn',
        component: SignOn,
        meta: { title: 'Connexion' }
    },
    {
        path: '/success_create',
        name: 'succes_create',
        component: Accountcomplet,
        meta: { title: 'Création terminée' }
    },
    {
        path: '/settings',
        name: 'settings',
        component: settings,
        meta: { title: 'Configuration' }
    },
    {
        path: '/check',
        name: 'check',
        component: auth,
        meta: { title: 'Vérification' }
    },
    {
        path: '/signup',
        name: 'signup',
        component: signUp,
        meta: { title: 'Inscription' }
    },
    {
        path: '/login_chose',
        name: 'next',
        component: next,
        meta: { title: 'Choix du login' }
    },
    {
        path: '/user',
        name: 'user',
        component: generalData,
        meta: { title: 'Informations Personnelles' }
    },
    {
        path: '/method',
        name: 'method',
        component: method,
        meta: { title: 'Identifiants' }
    },
    {
        path: '/otpsms',
        name: 'otpsms',
        component: OtpSms,
        meta: { title: 'Configuration' }
    },
    {
        path: '/recovery',
        name: 'recovery',
        component: recovery,
        meta: { title: 'Récupération' }
    },
    {
        path: '/number',
        name: 'number',
        component: number,
        meta: { title: 'Configuration' }
    },
    {
        path: '/recover',
        name: 'recover',
        component: New,
        meta: { title: 'Récupérer' }
    },
    {
        path: '/success',
        name: 'success',
        component: success,
        meta: { title: 'Réussi' }
    },
    {
        path: '/other_method',
        name: 'other',
        component: other,
        meta: { title: 'Autres moyens' }
    },
    {
        path: '/complet',
        name: 'complet',
        component: complet,
        meta: { title: 'Compte créer' }
    },
    {
        path: '/information',
        name: 'information',
        component: Data,
        meta: { title: "Informations de l'utilisateur" }
    },
    {
        path: '/history',
        name: 'history',
        component: history,
        meta: { title: 'Historique' }
    },
    {
        path: '/devices',
        name: 'devices',
        component: devices,
        meta: { title: 'Périfériques' }
    },
    {
        path: '/services',
        name: 'services',
        component: services,
        meta: { title: 'Services SYGALIN' }
    },
    {
        path: '/testModal',
        name: 'testModal',
        component: testmodal
    },
    {
        path: '/edit',
        name: 'edit',
        component: edit,
        meta: { title: 'Modification' }
    },
    {
        path: '/security',
        name: 'security',
        component: security,
        meta: { title: 'Page introuvable' }
    },
    {
        path: '/404',
        name: 'notfound',
        component: notFound,
        meta: { title: 'Page introuvable' }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
];

const router = createRouter({
    history: createWebHistory(process.env.baseUrl),
    routes
});


router.beforeEach((to, from, next) => {
    document.title = to.meta.title || "service d'authentification";
    next();
});



export default router;
