<template>

  <div>
    <Transition name="slide-fade">
      <div v-if="show" class="container-fluid d-flex justify-content-center align-items-center">
        <div class="col-sm-4 p-4 rounded-5 bg-white shadow">
          <div class="col-sm-12 d-flex align-content-start">
            <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS" class="img-fluid"
                 style="max-width: 60px; height: auto;">
            <span class="col-sm-5" style="color: #0D5286; margin-left: -5%; margin-top: 3%;
        font-family: MTNBrighterSans-Bold;
         font-size: 200%"
            ><b>SYGALIN SAS</b></span>
            <div class="position-relative">
              <div class="col-7">
                <img class="img-fluid" src="../../assets/img/femme_violette.svg"
                     style="width: 100%;
                 height: auto;
                 margin-left: 100%;
                 margin-top: -90%"
                     alt="La dame en qui manipule son pc"/>
              </div>
            </div>
          </div>
          <br/>
          <div class="small">
            <h4 class="mb-4" style="color: #0D5286" ><b>Voulez-vous vraiment annuler ?</b></h4>
           </div>
          <div>
            <img class="position-relative" src="../../assets/img/question_tag.svg" style="
          background: none;
          margin-bottom: -100px;
          width: 40%; height: auto">
          </div>
          <br/>
          <div class="position-relative">
            <img class="position-absolute" src="../../assets/img/homme_gros.svg" style="
          margin-top: 17%;
          margin-left: -70%;
            width: 40%;
            height: auto;"
                 alt="image d'un homme gros"/>
          </div>
          <div class="row" style="margin-top: 10%">
            <div class="col-sm-12 d-flex justify-content-center">
              <button type="submit" class="my-button bg-danger" @click="show = !show"
                      style="margin: 20% 0 0 0">Non</button>
              <router-link to="/information" style="margin: 20% 0 0 0">
                <button type="submit" class="my-button" >Oui</button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="img-fluid col-sm-5 d-none d-sm-none d-md-block d-lg-block">
          <img class="img-fluid" src="../../assets/img/Globe.svg"
               style="max-width: 100%; height: auto;  margin-left: 50px;" alt="image du globe"/>
        </div>
      </div>
    </Transition>
  </div>
  <div v-if="!show" class="container-fluid transit d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounded-5 bg-white shadow">
      <div class="col-sm-12 d-flex align-content-start">
        <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS" class="img-fluid"
             style="max-width: 60px; height: auto;">
        <span class="col-sm-5" style="color: #0D5286; margin-left: -5%; margin-top: 3%;
         font-size: 200%"
        ><b>SYGALIN SAS</b></span>
        <div class="position-relative">
          <div class="col-7">
            <img class="img-fluid" src="../../assets/img/femme_violette.svg"
                 style="width: 100%;
                 height: auto;
                 margin-left: 100%;
                 margin-top: -90%"
                 alt="La dame en qui manipule son pc"/>
          </div>
        </div>
      </div>
      <br/>
      <div class="small">
        <h4 class="mb-4" style="color: #0D5286" ><b>Modifier votre nom!</b></h4>
        <p class="MTNBrighterSans-Bold-ligh" style="margin-top: -25px">Veuillez renseigner votre nouveau nom!</p>
      </div>
      <br/>
      <form @submit.prevent="handleSubmit">
        <div class="mb-2 row align-items-center d-flex justify-content-center">
          <div class="col-sm-9">
            <input type="text" placeholder="Iby"
                   class="form-control placeholder-bold rounded-pill" id="name" v-model="name"
                   style="background-color: #F6F8FA;"/>
            <span v-if="nameError" class="text-danger" style="margin-top: -20px">Le nom est requis.</span>
          </div>
        </div>
        <div class="position-relative">
          <img class="position-absolute" src="../../assets/img/homme_gros.svg" style="
          margin-top: 17%;
          margin-left: -70%;
            width: 40%;
            height: auto;"
               alt="image d'un homme gros"/>
        </div>
        <div class="row" style="margin-top: 10%">
          <div class="col-sm-12 d-flex justify-content-end">
            <button type="submit"
                    class="my-button bg-danger"
                    style="margin: 20% 0 0 -50%"  @click="show = !show">Annuler</button>
            <router-link to="/information" style="margin: 20% 0 0 0">
              <button type="submit" class="my-button" >Suivant</button>
            </router-link>
          </div>
        </div>
      </form>
    </div>
    <div class="img-fluid col-sm-5 d-none d-sm-none d-md-block d-lg-block">
      <img class="img-fluid" src="../../assets/img/Globe.svg"
           style="max-width: 100%; height: auto;  margin-left: 50px;" alt="image du globe"/>
    </div>
  </div>
</template>


<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "edit",
  data() {
    return {
      show:false,
      name: '',
      nameError: false,
    };
  },
  methods: {
    handleSubmit() {
      if (!this.name.trim()) {
        this.nameError = true;
      }else {
        this.$router.push('/')
      }
    }
  }
};
</script>

<style scoped>

.MTNBrighterSans-Bold-Sygalin {
  font-family: "MTNBrighterSans", sans-serif;
  font-size: 12px;
  color: #0D5286;
}
.my-button1 {
  background-color: #8EBE45;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.my-button:hover {
  cursor: pointer!important;
}
.MTNBrighterSans-Bold-Sygalin {
  font-family: "MTNBrighterSans", sans-serif;
  font-size: 11px;
  color: #0D5286;
}
.MTNBrighterSans-Bold-ligh {
  font-family: "MTNBrighterSans", sans-serif;
  font-size: 12px;
  color: #B1B7C4;
}
.placeholder-bold::placeholder {
  font-weight: bold;
  color: #0D5286;
}
body {
  padding-left: 0!important;
  padding-right: 0!important;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-200px);
  opacity: 0;
}
</style>
