<template>
  <div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="col-md-4 p-4 rounde shadow bg-white responsiv"  >
      <div v-if="isLoading" class="spinner-overlay">
        <div class="spinner"></div>
      </div>
      <div class="col-sm-12 " >
        <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS"
             class="img-fluid position-relative " style="max-width: 60px;'' height: auto;margin-top: -3%;">
        <span class="mb-3">
          <b class="position-relative"
             style="font-family: MTNBrighterSans-Bold,sans-serif; font-size: 20px; color: #134973;
              margin-left: 0; margin-top: 100%"
          >SYGALIN SAS</b>
        </span>
      </div>
      <br/>
      <div class="small">
        <h5 class="mb-4" style="color: #134973 "><b>Informations générales!</b></h5>
        <p class="MTNBrighterSans-Bold-ligh" style="margin-top: -25px"
        >Saisissez votre date de naissance, votre genre!</p>
      </div>
      <br/>
        <form @submit.prevent="handleSubmit">
          <div class="col-sm-12 justify-content-center" style="padding-left: 30px">
          <div class="justify-content-center col-sm-12">
          <div class="mb-3 row">
            <div class="d-flex justify-content-center ">
            <div class="col-sm-3"><div class=" ">
              <select :disabled="isSubmitting" required class="form-select custom-select rounded-pill" v-model="selectedDay" style="background-color: #F6F8FA;">
                <option value="jour">jour</option>
                <option value="01" >01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option v-if="selectedMonth !== '02' " value="29">29</option>
                <option v-if="selectedMonth === '02' && bissex === true" value="29">29</option>
                <option v-if="selectedMonth !== '02' " value="30">30</option>
                <option v-if="selectedMonth !== '02' " value="31">31</option>
              </select>
            </div>
<!--              <input type="date" name="party" min="2017-04-01" max="2017-04-30" />-->
<!--              <input required :disabled="isLoading" type="text" id="numberInput1" maxlength="2"-->
<!--                     class="form-control placeholder-bold rounded-pill"-->
<!--                     placeholder="Jour"-->
<!--                     style="background-color: #F6F8FA;"-->
<!--                     inputmode="numeric" pattern="[0-9]*"-->
<!--                     v-model="day"-->
<!--                     @input="validateDay"/>-->
          </div>
              <div class="col-sm-3">
            <div class="">
              <select :disabled="isSubmitting" required class="form-select custom-select rounded-pill" v-model="selectedMonth" style="background-color: #F6F8FA;">
                <option value="mois">Mois</option>
                <option value="01">Janvier</option>
                <option value="02">Février</option>
                <option value="03">Mars</option>
                <option value="04">Avril</option>
                <option value="05">Mai</option>
                <option value="06">Juin</option>
                <option value="07">Juillet</option>
                <option value="08">Aout</option>
                <option value="09">Septembre</option>
                <option value="10">Octobre</option>
                <option value="11">Novembre</option>
                <option value="12">Decembre</option>
              </select>
            </div>
<!--                <span v-if="selectedMonth === 'mois'" class="text-danger">Votre mois de naissance ! </span>-->
            </div>
            <div class="col-sm-3">
              <div class="">
              <select :disabled="isSubmitting" class="form-select custom-select rounded-pill" v-model="selectedYear">
                <option value="annee">Année</option>
                <option @click="checkLeapYear" v-for="yearOption in yearOptions" :key="yearOption" :value="yearOption">
                  {{ yearOption }}
                </option>
              </select>
<!--              <input type="text" id="numberInput2" maxlength="4"-->
<!--                     class="form-control placeholder-bold rounded-pill" placeholder="Année"-->
<!--                     style="background-color: #F6F8FA;" v-model="year" @input="validateYear" required/>-->
          </div>
          </div>
          </div>
        </div>
        <div class="mb-2 row align-items-center d-flex justify-content-center">
          <div class="col-sm-9">
            <div class="">
            <select class="form-select custom-select rounded-pill"  v-model="SelectGender"
                    style="background-color: #F6F8FA;" required :disabled="isSubmitting" >
              <option value="Genre" style="color: #0C5286; opacity: 0.7" class="">Genre</option>
              <option value="M" class="">Masculin</option>
              <option value="F" class="">Féminin</option>
              <option value="F" class="">Non binaire</option>
            </select>
            </div>
          </div>
          </div>
        </div>
        </div>
        <div class="col-md-12 d-flex justify-content-end">
          <button type="submit" class="my-button" style="margin-top:20%">
            <span v-if="!isSubmitting" id="button-text">Suivant</span>
            <div v-else class="spinner" id="spinner"></div>
          </button>
        </div>
      </form>
    </div>
    <div class="img-fluid dame d-flex col-sm-5 d-sm-none d-md-block d-lg-block">
      <img class="dame" src="../../assets/img/Naissanace.svg" style="background-color: transparent;
         max-width: 700px;'' height: auto; margin-left: 60px"
           alt="image du globe"/>
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "generalData",
  data() {
    return {
      day:'',
      timer:null,
      bissex:false,
      isLoading:false,
      SelectGender : 'Genre',
      selectedMonth: 'mois',
      selectedDay: 'jour',
      selectedYear: 'annee',
      isSubmitting:false,
      currentYear: null,
      year: null,
      isLeapYear: false,
      yearOptions: [],
    };
  },
  mounted() {
    const now = new Date();
    this.currentYear = now.getFullYear();
    this.year = this.currentYear;
      const startYear = this.currentYear - 6;
      const endYear = this.currentYear - 106;
      for (let year = startYear; year >= endYear; year--) {
        this.yearOptions.push(year);
      }
      this.year = this.yearOptions[0];
  },
  created() {
    const selectedYear = localStorage.getItem('selectedYear');
    const selectedMonth = localStorage.getItem('selectedMonth');
    const selectedDay = localStorage.getItem('selectedDay');
    const gender = localStorage.getItem('gender');
    if (selectedYear && selectedMonth && selectedDay && gender) {
      this.selectedYear = selectedYear;
      this.selectedMonth = selectedMonth;
      this.selectedDay = selectedDay;
      this.SelectGender = gender;
    }
  },
methods: {
  checkLeapYear() {
    const y = this.selectedYear;
    if ((y % 4 === 0 && y % 100 !== 0) || y % 400 === 0 ) {
      this.bissex = true
    }
    else{
      this.bissex = false
    }
  },
  handleSubmit(){
    if (this.selectedYear !== 'annee' && this.selectedMonth !== 'mois' && this.selectedDay !== 'jour' && this.SelectGender !== 'Genre'){
      this.isSubmitting = true;
        const birth_date = this.selectedYear+'-'+this.selectedMonth+'-'+this.selectedDay;
        const gender = this.SelectGender;
      localStorage.setItem('birth_date', birth_date);
      localStorage.setItem('selectedYear', this.selectedYear);
      localStorage.setItem('selectedMonth', this.selectedMonth);
      localStorage.setItem('selectedDay', this.selectedDay);
      localStorage.setItem('gender', gender);
      setTimeout(() => {
        this.isSubmitting = false;
        localStorage.setItem('timeout', this.remainingTime);
        this.$router.push('/method');
      }, 5000);
    }
  }
}
}
</script>

<style >
.MTNBrighterSans-Bold-Sygalin {
  font-family: MTNBrighterSans, sans-serif;
  font-size: 11px;
  color: #0E5386;
}
.MTNBrighterSans-Bold-ligh {
  font-family: MTNBrighterSans, sans-serif;
  font-size: 12px;
  color: #B1B7C4;
}
.placeholder-bold::placeholder {
  font-weight: bold;
  color: #a3a9b7;
}
.custom-option{
  color: #a3a9b7;
}


.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.my-button {
  background-color: #8EBE45!important;
  z-index: 10;
  color: #fff;
  font-size: 15px;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select {
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ddd;
  appearance: none;
  background-color: #f6f8fa;
  color: #8EAEC7;
}

.custom-select:focus {
  outline: none;
  border-color: #0d5286;
}

.custom-select-wrapper::after {
  content: '▼';
  font-size: 16px;
  color: #0d5286;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.my-button:hover {
  background-color: #0056b3;
}
@media screen and (max-width: 678px) {
  .triangle {
    margin-top: 16%;
    margin-left: 65%;
    border-style: solid;
    border-width: 10px;
    border-color: #8EAEC7 transparent transparent transparent ;
  }
  .triangle3 {
    margin-top: 4%;
    margin-left: 92%;
    border-style: solid;
    border-width: 10px;
    border-color: #8EAEC7 transparent transparent transparent ;
  }
}


</style>