<template>
  <div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounded-5 shadow bg-white" style="margin-top: -10%" >
      <div class="position-absolute">
        <img class="" src="../../assets/img/dame_en_veste_jaune.svg" style="
          margin-top: -60%;
          margin-left: -160%;
            top: 652px;
            left: 247px;
            width: 274px;
            height: 268px;
            padding-left: 65%"
             alt="image du globe"/>
      </div>
      <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS"
           class="img-fluid " style="max-width: 60px;'' height: auto;">
      <span class="col-sm-5" style="color: #0D5286; margin-left: -2%; margin-top: 7%; font-size: 200%">
           <b>SYGALIN SAS</b>
        </span >
      <br/><br/>
      <br/>
      <div class="small">
        <h5 class="mb-3 MTNBrighterSans-Bold"><b>Choix du deuxième facteur <br>d'authentification</b></h5>
        <p class="MTNBrighterSans-Bold-ligh" style="margin-top: -18px"
        >Vous avez le choix parmi les facteurs suivants</p><br/>
      </div>
      <div class="d-flex justify-content-center row col-md-12">
        <div class="col-6">
          <router-link to="/number" class="router-link">
            <img src="../../assets/img/methods/logo-whatsapp.svg" class="col-6" alt="logo de whatsapp">
            <span > <b>WhatsApp</b></span>
          </router-link>
        </div>
        <div class="col-6" >
          <router-link to="/otpsms" class="router-link">
            <img src="../../assets/img/methods/sms.svg" class="col-6"  alt="logo de sms">
            <span > <b>SMS <br/>
            </b></span>
          </router-link>
        </div>
        <div class="col-6">
          <br/>
          <router-link to="/qrinfo" class="router-link">
            <img src="../../assets/img/methods/mail-unread.svg" class="col-6"  alt="logo d'un email reçu et non lu">
            <span ><b>E-mail</b></span>
          </router-link>
        </div>
        <div class="col-6">
          <br/>
          <router-link to="/qrinfo" class="router-link">
            <img src="../../assets/img/methods/qr-code.svg" class="col-5"  alt="logo du code QR">
            <span ><b>Application OTP </b></span>
          </router-link>
        </div>
      </div>
      <br/>
    </div>
    <div class="img-fluid col-6 d-none d-sm-none d-md-block d-lg-block">
      <img class="img-fluid" src="../../assets/img/securité%20dame.svg"
           style="max-width: 100%; height: auto; margin-top: 15%; margin-left: 20%;" alt="image du mail">
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "settings",
  methods:{
    whatsapp(){
      this.$router.push('/check')
    }
  }
}
</script>

<style scoped>

.router-link {
  text-decoration: none;
  color: #0a0a0a;
}
</style>