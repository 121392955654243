<template>
  <div class="col-sm-12">
    <nav class="navbar navbar-expand-sm bg-white fixed-top" style="height: 70px!important">
      <div class="col-sm-2 align-items-start" style="margin-left: -03%">
        <img src="../assets/img/logo.png" alt="logo SYGALIN SAS"
             class="img-fluid position-relative " style="margin: -3% 0 0 0 ;
              max-width: 50px;'' height: auto;">
        <a class="router-link font-brand" style="font-size: 18px; color: #134973; margin-left: -3%" href="#">MY Sygalin</a>
      </div>
      <div class="container" style="height: 70px!important">
        <div class="navbar-collapse justify-content-start" id="navbarNav">
          <form class="form-inline my-2 my-lg-0" style="margin: 0 0 0 3%">
            <input class="form-control position-relative rm-sm-5" type="search"
                   style="width: 130%;
                   margin: 0 0 5% -20%"
                   placeholder="Rechercher dans My Sygalin"
                   aria-label="Search"/>
              <n class="fas fa-search position-absolute" style="margin: -3% 0 0 7%;font-size: 15px;"></n>
          </form>
          <ul>
          </ul>
        </div>
        <div class="row position-relative flex justify-between" style="flex-grow: revert ">
<!--          <div class="containerI" style="padding:0 0 0 0 ;-->
<!--              width: 26px;-->
<!--              height: 10px;-->
<!--              margin: 0 0 0 -40%">-->

            <ul class="navbar-nav me-auto mb-3 mb-lg-0">
              <li class="nav-item containerI font-brand" style="margin-left: -45%">
                <img class="position-absolute box" src="../assets/img/itemsSidebar/market.svg"
                     style="margin: 0 2% 0 0; width: 50px; height: auto" @click="toggleModal1"/>
                <div class="col-sm" >
                  <p class="font-brand position-absolute hidden-text col-sm-12" style="margin-left: -30%"
                     @click="toggleModal1">Nos services</p>
                </div>
              </li>
              <li class="nav-item font-brand" style="margin-left: -10%">
                <services-modal  v-if="showModal1" @click.self="hideModal1" class="position-absolute"
                                 style="margin: 50% 200% 0 -180%" />
              </li>
            </ul>


<!--          </div>-->

          <div class="">
            <img @click="toggleModal" src="../assets/img/itemsSidebar/profil.svg"
                 style="margin: 0 2% 0 10%;
                  cursor: pointer;
                  width: 50px; height: auto" />
            <user-modal  v-if="showModal" @click.self="hideModal"
                         class="position-absolute" style="margin: 0 0 0 -270%"/>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

import UserModal from "@/components/userModal.vue";
import ServicesModal from "@/components/servicesModal.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'UserNavbar',
  data() {

    return {
      showModal: false,
      showModal1: false
    }
  },
  components: {
    ServicesModal,
    UserModal
  },
    methods: {
      toggleModal() {
        this.showModal = !this.showModal;
        this.showModal1 = false;
        if (this.showModal) {
          document.addEventListener('click', this.hideModalOutside);
        } else {
          document.removeEventListener('click', this.hideModalOutside);
        }
      },
      hideModal() {
        this.showModal = false;
        this.showModal1 = false;
        document.removeEventListener('click', this.hideModalOutside);
      },
      hideModalOutside(event) {
        if (!this.$el.contains(event.target)) {
          this.hideModal();
        }
      },toggleModal1() {
        this.showModal1 = !this.showModal1;
        if (this.showModal1) {
          document.addEventListener('click', this.hideModalOutside1);
        } else {
          document.removeEventListener('click', this.hideModalOutside1);
        }
      },
      hideModal1() {
        this.showModal1 = false;
        document.removeEventListener('click', this.hideModalOutside1);
      },
      hideModalOutside1(event) {
        if (!this.$el.contains(event.target)) {
          this.hideModal1();
        }
      },
    }
}
</script>

<style>
@media screen and (max-width: 1278px) {
  .sideNav{
    display: none;
  }
  body{
    padding-left: 0 !important;
  }
}
.box {
  width: 10px;
  height: 20px;
  transition: transform 1s ease;
  cursor: pointer;
}

.hidden-text {
  font-size: 14px;
  margin: 25% 0% 0% -36%;
  color: #134973 ;
  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: 0;
}


.containerI:hover .box {
  transform: translateX(-90px);
}

.containerI:hover .hidden-text {
  opacity: 1;
}




</style>
