<template>
  <div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounde shadow bg-white responsive"  >
      <div class="col-sm-12 " >
        <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS"
             class="img-fluid position-relative " style="max-width: 60px;'' height: auto; margin-top: -3%">
        <span class="mb-3 MTNBrighterSans-Bold-Sygalin " style="margin-left: 0">
          <b style="font-family: MTNBrighterSans-Bold,sans-serif;
               font-size: 20px">SYGALIN SAS</b>
        </span>
        <div class="position-relative">
          <div class="col-1" style="padding-left: 85%;">
            <img class="position-absolute mirrored-image dame" src="../../assets/img/dame_en_veste_jaune.svg"
                 style="width: 30%;
                 height: auto;
                 margin-top: -40%;"
                 alt="La dame en veste jaune"/>
          </div>
          </div>
        </div>
      <div class="small">
        <h4 class="mb-4 MTNBrighterSans-Bold "  style="font-size: 20px" ><b>Méthode de connexion<br/>au compte!<div class="tooltip-container">
          <button class="tooltip-button my-button"
                  style="border-radius: 100%; padding-top: 10%; padding-bottom: 10% "
          >i
          </button>
          <span class="tooltip-text" style="font-size: 15px"
          >Insérez votre email, sélectionnez votre pays et renseignez votre numéro de téléphone</span>
        </div></b></h4>
    </div>
      <br/><br/>
      <form @submit.prevent="submit">
        <div class="col-10" >
            <div class="form-check d-block col-12 align-items-center">
              <input  class="form-control col-10 rounded-pill placeholder-bold" type="email"
                     email="exampleRadios" id="exampleRadios1"
                     @input="typing" required :disabled="isSubmitting"
                     placeholder="Insérer l'adresse e-mail" v-model="email"
                     style="background-color: #F6F8FA;"/>
              <p>
                <span class="text-danger" style="font-size: .9375rem; margin-left: 08px" v-if="mailError">Cet e-mail est déjà utilisé. Essayez un autre.</span>
              </p>
              <div class="row col-sm-12 col-md-12 col-lg-12 col-xs-12">
              <div class="d-flex respond" style="width: 79%">
                <div class="dropdown col-sm-4 col-md-4 col-lg-4">
                  <button
                      class="my-button dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      :disabled="isSubmitting"
                      style="max-width: 90%; padding-left:10px!important;"
                  >
                    <img v-if="selectedCountry && selectedCountry.drapeau"
                         :src="selectedCountry.drapeau" style="width: 20px; height: auto;" />
                    <span v-if="selectedCountry">{{ selectedCountry.indicatif }}
          <span v-if="selectedCountry.indicatif === '' " value="+ 672">
              +672
          </span>
            </span>
                    <span v-else>Pays</span>
                  </button>
                  <ul class="dropdown-menu limited-dropdown" aria-labelledby="dropdownMenuButton2">
                    <li v-for="country in countries" :key="country.nom_fr_fr">
                      <label class="dropdown-item d-flex align-items-center" @click="selectCountry(country)">
                        <img v-if="country.drapeau" :src="country.drapeau" style="width: 20px; height: auto;" />
                        <span class="ms-2">{{ country.nom_fr_fr }}</span>
                      </label>
                    </li>
                  </ul>
                </div>
                <input :disabled="isSubmitting" class="form-control col-10 rounded-pill placeholder-bold " type="text"
                       maxlength="10"
                       placeholder="Numéro de téléphone"
                       id="number" value="" v-model="number"
                       @input="typingNumber"
                       style="background-color: #F6F8FA; padding-left: 08%;margin-left: 06%" required  />
            </div>
                <p style="margin-left: 10px">
                  <span class="text-danger" style="font-size: .9375rem;"
                        v-if="numberError">Ce numéro est déjà utilisé. Essayez un autre.</span>
                  <span class="text-danger" v-if="netError" style="font-size: .9375rem; margin-left: 08px">
                  Connexion à internet instable. Veuillez réessayer!
                  </span>
                  <span v-if="numError === true" class="text-danger" style="font-size: .9375rem;"
                  > Numéro invalide</span>
                  <span v-if="sessionError === true" class="text-danger" style="font-size: .9375rem;"
                  >Vous ne pouvez pas poursuivre. Veuillez recommencer!</span>
                </p>
            </div>
          </div>
        </div>
        <div class="position-absolute">
          <img class="dame" src="../../assets/img/Monsieur%20assis%20sur%20la%20chaise%20jaune.svg" style="
          margin-top: -58%;
          margin-left: -90%;
            top: 652px;
            left: 247px;
            width: auto;
            height: 80%;
            padding-top: 50%"
          />
        </div>
        <div class="col-md-12 d-flex justify-content-end" style="margin: 10% 0 0 0">
          <button :disabled="isSubmitting" type="submit" class="my-button">
            <span v-if="!isSubmitting" id="button-text">Suivant</span>
            <div v-else class="spinner" id="spinner"></div>
          </button>
        </div>
      </form>
    </div>
    <div class="img-fluid dame col-sm-5 d-sm-none d-md-block d-lg-block">
      <img class="dame" src="../../assets/img/ordi%20tel.svg" style="background-color: transparent;
         max-width: 700px;'' height: auto; margin-left: 60px"
           alt="image du globe"/>
    </div>
  </div>
</template>


<script>
import {baseUrl} from "@/store/baseUrl";
import {AsYouType, parsePhoneNumber} from "libphonenumber-js";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "next",
  components: {

  },
  data() {
    return {
      selectedCountry: localStorage.getItem('selectedCountry'),
      countries: [],
      errorMessage: '',
      mailError: false,
      numberError: false,
      isSubmitting: false,
      numError: false,
      error : false,
      email:localStorage.getItem('email'),
      sessionError:false,
      netError:false,
      number: localStorage.getItem('number'),
      numero:'',
      phoneNumber: null,
      isLoading: '',
      emailError: false,
      timer: null,
    };
  },
  mounted() {
    this.fetchCountries();
  },
  created() {
    const email = localStorage.getItem('email');
    const selectedCountry = localStorage.getItem('selectedCountry');
    const number = localStorage.getItem('number');
    if (email && selectedCountry && number) {
      this.email = email;
      this.selectedCountry = selectedCountry;
      this.number = number;
    }
  },
  methods: {

    typingNumber(){
      this.netError = false
      this.mailError = false;
      this.numberError = false;
      this.numError = false
      this.validateNumber(event);
    },
    typing(){
      this.netError = false
      this.mailError = false;
      this.numberError = false;
    },
    selectCountry(country) {
      this.selectedCountry = country;
      // console.log(this.selectedCountry)
    },
    async fetchCountries() {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des pays');
        }
        const data = await response.json();
        this.countries = data.map(country => ({
          nom_fr_fr: country.translations?.fra?.common || 'Nom non disponible',
          indicatif: country.idd?.root ? (country.idd.suffixes.length > 1 ? `${country.idd.root}` : `${country.idd.root}${country.idd.suffixes[0]}`) : '',
          alpha2: country.cca2,
          drapeau: country.flags?.svg || '' // Utilisation de l'image du drapeau
        })).sort((a, b) => a.nom_fr_fr.localeCompare(b.nom_fr_fr));
      } catch (error) {
        this.errorMessage = 'Erreur de chargement. Veuillez actualiser la page.';
      }
    },
    submit(){
      if (this.number.length > 5){
      if (this.selectedCountry !== null) {
        // console.log('listes 2 : ',this.selectedCountry)
        this.isSubmitting = true;
        this.emailError = false;
        this.netError = false
        let countryCode = this.selectedCountry.alpha2
      const phoneNumber = parsePhoneNumber(this.number, countryCode);
      if (phoneNumber) {
        this.phoneNumber = phoneNumber;
        if ( phoneNumber.isValid() === true && phoneNumber.isPossible() === true ){
          this.isSubmitting = false;
          this.handleSubmit()
        }else {
          this.isSubmitting = false;
          this.numError = true;
        }
        // console.log('Valide:', phoneNumber.isValid());
        // console.log('Possible:', phoneNumber.isPossible());
      } else {
        this.errorMessage = 'Numéro de téléphone invalide.';
      }
    }
   }else{
        this.isSubmitting = false;
        this.numError = true;
      }
  },
    validateNumber(event) {
      const value = event.target.value;
      const numericValue = value.replace(/\D/g, '');
      this.number = numericValue;
    },
  async handleSubmit(){
    if (this.email !== '' && this.number !== '' && this.selectedCountry !== null ) {
      // console.log('listes 3: ',this.selectedCountry)
      this.isSubmitting = true;
      this.emailError = false;
      this.netError = false
      // const firstSpaceIndex = this.selectedCountry.indexOf(" ");
      // const countryCode = this.selectedCountry.substring(1, firstSpaceIndex);
      const countryCode = this.selectedCountry.indicatif.substring(1);
      console.log(countryCode)
      const countryAlpha2 = this.selectedCountry.alpha2;
      // const countryAlpha2 = this.selectedCountry.substring(firstSpaceIndex + 1);
      localStorage.setItem('email', this.email);
      localStorage.setItem('phone_number', this.number);
      localStorage.setItem('phone_number_code', countryCode);
      localStorage.setItem('selectedCountry', this.selectedCountry);
      localStorage.setItem('iso2_country_code', countryAlpha2);

      const controller = new AbortController();
      const signal = controller.signal;
      const timeout  = setTimeout(() => {
        controller.abort();
        this.isSubmitting = false;
        this.netError = true
      }, 30000);
      try {
        const data ={
          session_id: localStorage.getItem('session_id'),
          phone_number: this.number,
          phone_number_code: countryCode,
          email:this.email
        }
        const response = await fetch(`${baseUrl}/account/v1/check-unicity/`,
            {
              method: 'post',
              headers: {
                // signal,
                // 'Authorization': 'tokken',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
              },
              body: JSON.stringify(data)
              // signal: signal
            });
        // console.log(data)
        this.respond = await response.json();
        if (this.respond !== null){
          clearTimeout(timeout);
        }
        console.log('réponse :', this.respond)
        if (this.respond.status === 200){
          this.isSubmitting = false;
          this.$router.push('/password');
        }else if (this.respond.status === 401){
          localStorage.removeItem('session_id');
          localStorage.removeItem('username');
          localStorage.removeItem('first_name');
          localStorage.removeItem('last_name');
          localStorage.removeItem('birth_date');
          localStorage.removeItem('iso2_country_code');
          localStorage.removeItem('gender');
          localStorage.removeItem('email');
          localStorage.removeItem('phone_number');
          localStorage.removeItem('phone_number_code');
          this.$router.push('/error');
        }else if (this.respond.status === 409){
          if (this.respond.message === 'This email is not available')
            this.mailError = true;
          this.isSubmitting = false;
        }
        if (this.respond.message === 'This phone number is not available')
          this.numberError = true;
        this.isSubmitting = false;
      }catch (e) {
        this.netError = true;
        this.isSubmitting = false;
      }
    }
  }
}
}
</script>

<style>
@media (min-width: 17px) and (max-width: 578px) {
  .responsiv {
    width: 80% !important;
  }
  .respond {
    width: 70% !important;
  }
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

/* Style pour le bouton */
.tooltip-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: none!important;
  border-radius: 5px;
}

/* Style pour le texte du tooltip */
.tooltip-text {
  visibility: hidden;
  width: 160px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position au-dessus du bouton */
  left: 50%;
  margin-left: -80px; /* Centrer le tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

/* Afficher le tooltip au survol */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.rounde {
  border-radius: 40px;
}
.MTNBrighterSans-Bold {
  font-family: "MTNBrighterSans-Bold", sans-serif;
  color: #0E5386;
}
.MTNBrighterSans-Bold-Sygalin {
  font-family: "MTNBrighterSans", sans-serif;
  font-size: 11px;
  color: #0E5386;
}
.MTNBrighterSans-Bold-ligh {
  font-family: Raleway, regular;
  font-size: 13px;
  color: #000000 !important;
}
.my-button {
  background-color: #0056b3;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.my-button:hover {
  background-color: #0056b3;
}
.custom-radio .form-check-input:checked + .form-check-label::before {
  background-color: #4a7ea4;
  border-color: #4a7ea4;
}

.custom-radio .form-check-input:checked + .form-check-label::after {
  color: #4a7ea4;
}

.custom-radio .form-check-input {
  border-color: #4a7ea4;
}

.custom-radio .form-check-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 128, 0.25);
}

.custom-radio .form-check-input:active {
  background-color: #4a7ea4;
  border-color: #4a7ea4;
}

.custom-radio .form-check-label {
  color: #4a7ea4;
}

.dropdown-menu.limited-dropdown {
  max-height: 300px; /* Ajustez la hauteur maximale selon vos besoins */
  overflow-y: auto;
}

.triangle2 {
  margin-top: 15px;
  margin-left: 86%;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px;
  opacity: 1;
  border-color: #8EAEC7 transparent transparent transparent ;
}

.custom-option{
  color: #a3a9b7;
}

.info-button {
  position: relative;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.info-button:hover .tooltip-text {
  opacity: 1;
  visibility: visible;
}
</style>
