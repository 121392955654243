<template>
  <div id="app" class="container">
    <div class="row justify-content-start">
      <div class="col-sm-4 col-lg-2 dropup-container">
        <div class="dropup rounded-circle sm-2 ">
          <div class="card">
            <div class="card-header" style="border-color: #0C5286" >
              <img src="../assets/img/logo.png" style=" margin: 0 0 0 -18%; width: 30px; height: auto" alt="logo de SYGALIN SAS">
              <span class="head font-brand">Services à valeur ajoutée</span>
            </div>
            <div  class="dropdown-menu col-sm-12 card-body">
              <div @mouseover="mouse" class="col-sm-12 canal flex justify-start align-items-start text-center">
                <img @mouseover="mouse" class="" src="../assets/img/itemsSidebar/logo_Canal.png"
                     style="margin: 0 2% 0 -10%;height: 10px; width: auto"/>
                <span @mouseover="mouse" style="font-size: 12px; margin-left: 5%" class="col-sm-12 font-brand text-center"
                >Réabonnez-vous en toute simplicité</span>
                <img v-if="isHovered === false" src="../assets/img/itemsSidebar/heart-hand.svg" class="heart-icon position-absolute"
                     style="height: 20px; width: auto;  margin: 0 0 0 5%; "/>
                <img v-else src="../assets/img/itemsSidebar/heart-handB.svg" class="heart-icon position-absolute"
                     style="height: 20px; width: auto;  margin: 0 0 0 5%; "/>
                <hr style="color: #0C5286; margin: 0 0 0 0"/>
              </div>
              <div @mouseover="mouse2" class="col-sm-12 starlink flex justify-start align-items-start text-center">
                <img @mouseover="mouse2" class="position-relative" src="../assets/img/itemsSidebar/starlink_Logo.png" alt=""
                     style="margin: 2% 0 0 -12%;height: 28px; width: auto"/>
                <span @mouseover="mouse2" style="font-size: 12px;
                background: transparent; margin-left: -7%" class="col-sm-12 font-brand text-center"
                >Commander votre kit maintenant</span>
                <img @mouseover="mouse2" src="../assets/img/itemsSidebar/heart-handB.svg" class="heart-icon position-absolute"
                     v-if="isHovered2 === false"  style="height: 20px; width: auto;  margin: 0 0 0 10%;"/>
                <img v-else src="../assets/img/itemsSidebar/heart-hand.svg" class="heart-icon position-absolute"
                      style="height: 20px; width: auto;  margin: 0 0 0 10%;"/>
                <hr style="color: #0C5286; margin: 0 0 0 0"/>
              </div>
              <div class="card-footer position-relative "
                   style="background-color: #0D5286; margin: 0 -10% -10% -10%"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "floatingBotton",
    data() {
      return {
        isHovered: false,
        isHovered2: false
      }
    },
    methods: {
      mouse(event){
        this.isHovered = !this.isHovered
      },
      mouse2(event){
        this.isHovered2 = !this.isHovered2
      }
    }
}
</script>

<style scope>

.footer:hover {
  background-color: #08406A; /* Change background color on hover */
}
.dropup-container {
  position: relative;
  transition: all 0.3s ease;
}

.dropup-container:hover {
  bottom: 140px;
}

.dropup-container:hover .card-header span.hover-text {
  color: blue;
  pointer:cursor;
}

.dropup {
  transition: all 0.3s ease;
}

.dropup:hover {
  transform: translateY(-20px) scale(1.1);
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.dropup:hover .dropdown-menu {
  display: block;
}

.logo {
  margin: 0 0 0 2%;
  width: 30px;
  height: auto;
}

.menu-logo {
  margin: 0 0 0 2%;
  height: 10px;
  width: auto;
}

.heart-icon {
  height: 20px;
  width: auto;
  color: #0D5286;
  position: absolute;
  margin: -2% -10% 0% 20%;
}
.dropup-container {
  position: fixed;
  bottom: 2%;
  left: 80%;
  transform: translateX(-50%);
  transition: all 0.3s ease;
}

.dropup-container:hover {
  bottom: 140px;
}

.dropup-container:hover .head {
  margin-right: -15%;
  font-size: 12px;
  color: #0D5286;
}

.dropup {
  transition: all 0.7s ease;
}

.dropup:hover {
  transform: translateY(-20px) scale(1.1);
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
.canal:hover span{
  cursor: pointer;
  color: #0C5286;

}
.starlink:hover span{
  cursor: pointer;
  color: #0C5286;
}
.dropup:hover .dropdown-menu {
  display: block;
}
</style>