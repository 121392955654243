<template>
  <div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounded-5 shadow bg-white" >
      <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS" class="img-fluid"
           style="max-width: 60px; height: auto;margin-top: -3%;">
      <span class="col-sm-5" style="color: #0D5286; margin-left: -2%;  font-size: 200%"><b>SYGALIN SAS</b></span>
      <br/>
      <br/>
      <br/>
      <div class="small">
        <h4 class="mb-3" style="color: #0D5286" ><b>Authentification!</b></h4>
        <p class="MTNBrighterSans-Bold-ligh" style="margin-top: -20px"
        >Saisissez le code qui vous a été envoyé!</p><br/>
        <p class="MTNBrighterSans-Bold-b" style="margin-top: -25px"
        ><b>Un message a été envoyé à votre numéro<br/>*******89 <br/><br/></b>
          </p>
      </div>
      <form @submit.prevent="handleClick">
      <div class="d-flex justify-content-center align-content-center">
        <v-otp-input
            input-classes="otp-input borde"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            id="otp"
            @on-change="handleOnChange"
            @on-complete="handleOnComplete"
            v-model="otpValue"
        />
      </div>
        <br/>
        <div class="d-flex justify-content-start " >
          <span style=" font-family: MTNBrighterSans, sans-serif; font-size: 15px;
          color: #6B982F" >
            <router-link to="#" class="router-link text-success"
          ><b>Renvoyer le code! </b></router-link> </span>
          <span v-if="countdown > 0" style="font-family: MTNBrighterSans, sans-serif; color: #134973; font-size: 15px"><b> {{ countdown }}</b></span>
        </div>
        <div class="col-4 position-">
          <div class="position-relative">
            <img class="" src="../../assets/img/Jolie_dame_assise.svg" style="
          margin-top: -20%;
          margin-left: 200%;
          width: 80%;
          height: auto;"
          alt="Dame assise à sa table"/>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <br>
          <br>
          <div class="position-relative d-flex justify-content-center">
            <button type="submit" class="my-button position-absolute">
              <span v-if="!isSubmitting" id="button-text">Valider</span>
              <div v-else class="spinner" id="spinner"></div>
            </button>
          </div>
        </div>
      </form>
      </div>
    <div class="img-fluid col-6 d-none d-sm-none d-md-block d-lg-block" style=" margin-left: 3%; margin-top: -5%;">
      <img class="img-fluid" src="../../assets/img/E-mail.svg"
           style="max-width: 100%; height: auto; " alt="image du mail">
    </div>
  </div>
  </template>

<script>
import VOtpInput from 'vue3-otp-input';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name:'auth',
  components: {
    VOtpInput,
  },
  data() {
    return {
      otpValues: ['', '', '', '', '', ''],
      showCountdown: false,
      countdown: 7,
      isNull: false,
      allFieldsFilled: false
    };
  },
  methods: {
    handleClick(){
      // if(this.countdown > 0){
      //   this.isNull = true;
      // }else {
      this.$cookies.set('myCookie', 'value')
      console.log(this.$cookies);
      this.$router.push('/dashboard')
    },
    handleKeyDown(index, event) {
      if (event.key === 'Backspace') {
        if (index > 0 && this.otpValues[index] === '') {
          this.otpValues[index - 1] = '';
        }
      } else if (event.key.match(/[0-9]/)) {
        if (index < this.otpValues.length - 1) {
          this.otpValues[index + 1] = event.key;
        }
      } else {
        event.preventDefault();
      }
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(this.timer);
      }
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  }
};
</script>

<style>
.MTNBrighterSans-Bold-b{
  font-size: 14px;
  color: #0D5286;
}
.borde {
  margin-left: 5px;
  width: 70%!important;
  height: 144%!important;
  border: 3px solid #0D5286;
  margin-top: 0%;
  font-size: 15px;
  margin-bottom: 0%;
  text-align: center;
}
.text-blue {
  color: #0D5286;
}
</style>
