<template>
  <div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounded-5 shadow bg-white" >
      <div class="position-absolute">
        <img class="" src="../../../assets/img/dame_en_veste_jaune.svg" style="
          margin-top: -60%;
          margin-left: -160%;
            top: 652px;
            left: 247px;
            width: 274px;
            height: 268px;
            padding-left: 65%"
             alt="image du globe"/>
      </div>
      <img src="../../../assets/img/logo.png" alt="logo SYGALIN SAS"
           class="img-fluid " style="max-width: 60px;'' height: auto; margin-top: -3%;">
      <span class="mb-3 MTNBrighterSans-Bold-Sygalin " >
      <span class="col-sm-5" style="color: #0D5286; margin-left: -2%; margin-top: 7%; font-size: 200%">
           <b>SYGALIN SAS</b>
        </span >
        </span>
      <br/>
      <br/>
      <div class="small">
        <h5 class="mb-3 MTNBrighterSans-Bold " ><b>Authentification!</b></h5>
        <h5 class="mb-3 MTNBrighterSans-Bold " ><b>Saisissez le code qui vous a été envoyé!</b></h5><br/>
        <p class="MTNBrighterSans-Bold-ligh" style="margin-top: -25px"
        >Un code a été envoyé dans votre application.</p><br/>
        <p class="MTNBrighterSans-Bold-b" style="margin-top: -25px"
        ><b><br/><br/></b>
        </p>
      </div>
      <form @click="handleClick">
        <div class="d-flex justify-content-center align-content-center">
          <v-otp-input
              class=""
              input-classes="otp-input borde"
              separator=""
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              id="otp"
              @on-change="handleOnChange"
              @on-complete="handleOnComplete"
              v-model="otpValue"
              style="font-size: 13px"
          />
        </div>
        <br/>
        <br/>
        <div class="justify-content-start" >
          <span style=" font-family: MTNBrighterSans, sans-serif; margin-left: -60%; font-size: 15px;
          color: #6B982F" >
            <router-link to="#" class="router-link text-success"
            ><b>Renvoyer le code! </b></router-link> </span>
          <span v-if="countdown > 0" style="font-family: MTNBrighterSans, sans-serif; color: #134973; font-size: 15px"><b> {{ countdown }}</b></span>
          <br/>
          <br/>
          <br/>
          <button type="submit" class="my-button">Valider</button>
          <br/>
          <br/>
          <br/>
        </div>
        <div class="col-4 position-relative">
          <div class="position-absolute">
            <img class="" src="../../../assets/img/Jolie_dame_assise.svg" style="
          margin-top: -100%;
          margin-left: 200%;
            width: 100%;
            height: auto;"
                 alt="Dame assise"/>
          </div>
        </div>
      </form>
    </div>
    <div class=" col-sm-4 d-sm-none d-md-block d-lg-block" style="padding-left: 2%; margin-left: 5%">
      <img class="" src="../../../assets/img/securité%20dame.svg" style="background-color: transparent;
         max-width: 500px;'' height: auto;
            padding-top: 50%"
           alt="image de la dame cadenas"/>
    </div>
  </div>
</template>

<script>
import VOtpInput from 'vue3-otp-input';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name:'OtpQr',
  components: {
    VOtpInput,
  },
  data() {
    return {
      otpValues: ['', '', '', '', '', ''],
      showCountdown: false,
      countdown: 7,
      isNull: false,
    };
  },
  methods: {
    handleClick(){
      // if(this.countdown > 0){
      //   this.isNull = true;
      // }else {

      this.$router.push('/dashboard')
      // if (length(this.otpValues)){
      // }
      // }
    },
    handleKeyDown(index, event) {
      if (event.key === 'Backspace') {
        if (index > 0 && this.otpValues[index] === '') {
          this.otpValues[index - 1] = '';
        }
      } else if (event.key.match(/[0-9]/)) {
        if (index < this.otpValues.length - 1) {
          this.otpValues[index + 1] = event.key;
        }
      } else {
        event.preventDefault();
      }
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(this.timer);
      }
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  }
};
</script>

<style>
.my-button {
  background-color: #8EBE45;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 15px;
}
.MTNBrighterSans-Bold-b{
  font-size: 14px;
  color: #0D5286;
}
.borde {
  margin-left: 5px;
  width: 80%;
  height: 200%;
  border: 3px solid #0D5286;
}
.text-blue {
  color: #0D5286;
}
</style>
