<template>
  <div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounded-5 shadow bg-white" style="margin-top: -10%" >
      <div class="position-absolute">
        <img class="" src="../../../assets/img/dame_en_veste_jaune.svg" style="
          margin-top: -60%;
          margin-left: -160%;
            top: 652px;
            left: 247px;
            width: 274px;
            height: 268px;
            padding-left: 65%"
             alt="image du globe"/>
      </div>
      <img src="../../../assets/img/logo.png" alt="logo SYGALIN SAS"
           class="img-fluid " style="max-width: 60px;'' height: auto;">
      <span class="col-sm-5" style="color: #0D5286; margin-left: -2%; margin-top: 7%; font-size: 200%">
           <b>SYGALIN SAS</b>
        </span >
      <br/><br/>
      <br/>
      <div class="small">
        <h5 class="mb-3 MTNBrighterSans-Bold " ><b>Choix de récupération <br>de compte</b></h5>
        <p class="MTNBrighterSans-Bold-ligh" style="margin-top: -18px"
        >Par quel méthode voulez-vous récupérer votre compte?</p><br/>
      </div>
      <div class="d-flex justify-content-center row col-md-12">

        <div class="col-3">
          <router-link to="/number" class="router-link">
              <img src="../../../assets/img/methods/logo-whatsapp.svg" alt="logo de whatsapp">
              <span > <b>WhatsApp</b></span>
            </router-link>
          </div>
        <div class="col-3" >
          <router-link to="/otpsms" class="router-link">
            <img src="../../../assets/img/methods/sms.svg" alt="logo de sms">
            <span > <b>SMS</b></span>
          </router-link>
        </div>
        <div class="col-3">
          <router-link to="/qrinfo" class="router-link">
            <img src="../../../assets/img/methods/qr-code.svg" alt="logo du code QR">
            <span ><b>Application d’authentification</b></span>
          </router-link>
        </div>
      </div>
      <br/>
      <br/>
      <br/><br/>
      <br/>
      <br/>
      <br/>
<!--<br/>-->
<!--      <br/>-->
<!--      <br/>-->
<!--      <div class="justify-content-start" >-->
<!--&lt;!&ndash;        <br/>&ndash;&gt;-->
<!--&lt;!&ndash;        <br/>&ndash;&gt;-->
<!--&lt;!&ndash;        <br/>&ndash;&gt;-->
<!--&lt;!&ndash;        <br/>&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="col-4 position-relative">-->
<!--        <div class="position-absolute">-->
<!--          <img class="" src="../../../assets/img/securité%20dame.svg" style="-->
<!--          margin-top: -100%;-->
<!--          margin-left: 200%;-->
<!--            width: 100%;-->
<!--            height: auto;"-->
<!--               alt="Dame assise"/>-->
<!--        </div>-->
<!--      </div>-->
<!--            <button type="submit" class="my-button">Valider</button>-->
    </div>
    <div class="img-fluid col-6 d-none d-sm-none d-md-block d-lg-block">
      <img class="img-fluid" src="../../../assets/img/securité%20dame.svg"
           style="max-width: 100%; height: auto; margin-top: 15%; margin-left: 20%;" alt="image du mail">
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Other",
  methods:{
    whatsapp(){
      this.$router.push('/check')
    }
  }
}
</script>

<style scoped>

.router-link {
  text-decoration: none;
  color: #0a0a0a;
}
</style>