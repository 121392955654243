<template>
  <div class="container-fluid d-flex justify-content-center align-items-center" style="margin-top: -12%">
    <div class="col-sm-4 p-4 rounde shadow bg-white responsive"  >
      <div class="col-sm-12" >
        <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS"
             class="img-fluid " style="max-width: 60px;'' height: auto; margin-top: -3%;">
        <span class="mb-3 MTNBrighterSans-Bold-Sygalin " >
      <span class="col-sm-5" style="color: #0D5286; margin-left: -2%; margin-top: 7%; font-size: 200%">
           <b>SYGALIN SAS</b>
        </span >
        </span>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <div class="">
        <br/>
        <h3 class="mb-4 "
                style=" color: #8EBE45">
        <b>Votre compte <br/>a été configuré <br/>avec succès!</b></h3>
      </div>
      <br/>
      <br/>
      <div class="col-sm-12">
        <div class=" d-flex justify-content-center ">
          <router-link to="/signin">
            <button @click="redirect" type="submit" class="my-button" style="background-color: #eaeded">Terminer</button>
          </router-link>
          <br/>
        </div>
      </div>
    </div>
    <div class=" col-sm-5 d-sm-none d-md-block d-lg-block">
      <img class="" src="../../assets/img/victoria.svg" style="background-color: transparent;
         max-width: 700%;'' height: auto; margin-left: 60px; padding-top: 50%"
           alt="image du globe"/>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "completed",
  methods:{
    redirect(){
      const route = useRoute()
      const queryStrings = new URLSearchParams(this.$route.query).toString()
    }
  }
}
</script>

<style scoped>
.router-link {
  text-decoration: none;
}
.p{
  font-family: MTNBrighterSans, sans-serif;
  font-size: 19px;
  color: #1c7bc5;
}
.o{
  font-family: MTNBrighterSans, sans-serif;
  font-size: 15px;
}

</style>