<template>
  <div class="sidebar position-absolute"
       :class="{ 'open': isOpened }"
        style="height:auto;
        top: 15%">
    <div style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        max-height: calc(70% - 60px);">
      <div id="my-scroll"
           style="margin: -2% 0 0 -8%">
        <ul class="nav-list"
            style="overflow: visible">
          <li class="position-relative" style="margin: -2% 0 0 0" :class="{ active: isLinkActive('/dashboard') }">
            <router-link class="" to="/dashboard" :class="{ active: isLinkActive('/dashboard') }">
              <img src="../assets/img/itemsSidebar/acceuil.svg" v-if="!isLinkActive('/dashboard')"
                   style="margin-left:0; width: 20px; height: auto " class="position-relative" alt="accueil"/>
              <img src="../assets/img/itemsSidebar/acceuilW.svg" v-else
                   style="margin-left:0; width: 20px; height: auto " alt="accueil"/>
              <i class="bx"/>
              <span v-if="showTextLabels" :class="{ active: isLinkActive('/dashboard') }"
                    class="router-link font-brand"
                    style="margin-left: -19%; font-size: 14px;">Accueil</span>
            </router-link>
          </li>
          <li class="position-relative" style="margin: -2% 0 0 0" :class="{ active: isLinkActive('/information') }" >
            <router-link to="/information" :class="{ active: isLinkActive('/information') }">
              <img class="position-relative" src="../assets/img/itemsSidebar/info.svg" v-if=" !isLinkActive('/information')"
                   style="margin-left:0; width: 20px; height: auto " alt="Info utilisateur"/>
              <img src="../assets/img/itemsSidebar/infoW.svg" v-else
                   style="margin-left:0; width: 20px; height: auto " alt="Info utilisateur"/>
              <i class="bx"/>
              <span v-if="showTextLabels" class="router-link font-brand"
                    :class="{ active: isLinkActive('/information') }"
                    style="margin-left: -19%; font-size: 14px; "
              >Informations personnelles</span>
            </router-link>
          </li>
          <li class="position-relative" style="margin: -2% 0 0 0" :class="{ active: isLinkActive('/security') }" >
            <router-link to="/security" :class="{ active: isLinkActive('/security') }">
              <img class="position-relative" src="../assets/img/itemsSidebar/securite.svg" v-if="!isLinkActive('/security')"
                   style="margin-left:0; width: 20px; height: auto " alt="sécurité"/>
              <img src="../assets/img/itemsSidebar/securiteW.svg" v-else
                   style="margin-left:0; width: 20px; height: auto " alt="sécurité"/>
              <i class="bx" />
              <span v-if="showTextLabels" class="router-link font-brand"
                    :class="{ active: isLinkActive('/security') }"
                    style="margin-left: -19%; font-size: 14px; ">Sécurité</span>
            </router-link>
          </li>
          <li class="position-relative" style="margin: -2% 0 0 0%" :class="{ active: isLinkActive('/history') }">
            <router-link to="/history" :class="{ active: isLinkActive('/history') }">
              <img class="position-relative" src="../assets/img/itemsSidebar/calendar-days-solid.svg"
                   v-if=" !isLinkActive('/history') "
                   style="margin-left:0; width: 15px; height: auto " alt="calendrier"/>
              <img  v-else src="../assets/img/itemsSidebar/calendar-days-solidW.svg"
                   style="margin-left:0; width: 15px; height: auto " alt="calendrier"/>
              <i class="bx" />
              <span v-if="showTextLabels" class="router-link font-brand"
                    :class="{ active: isLinkActive('/history') }"
                    style="margin-left: -19%; font-size: 14px;"
              >Historique</span>
            </router-link>
          </li>
          <li class="position-relative" style="margin: -2% 0 0 0%" :class="{ active: isLinkActive('/devices') }">
            <router-link to="/devices" :class="{ active: isLinkActive('/devices') }">
              <img class="position-relative" src="../assets/img/itemsSidebar/peripherique.svg" v-if="!isLinkActive('/devices')"
                   style="margin-left:0; width: 20px; height: auto " alt="périfériques"/>
              <img class="position-relative" src="../assets/img/itemsSidebar/peripheriqueW.svg" v-else
                   style="margin-left:0; width: 20px; height: auto " alt="périfériques"/>
              <i class="bx" />
              <span v-if="showTextLabels" class="router-link font-brand"
                    :class="{ active: isLinkActive('/devices') }"
                    style="margin-left: -19%; font-size: 14px; "
              >Périphériques</span>
            </router-link>
          </li>
          <li class="position-relative" style="margin: -2% 0 0 0" :class="{ active: isLinkActive('/services') }">
            <router-link to="/services" :class="{ active: isLinkActive('/services') }">
              <img class="position-relative" src="../assets/img/itemsSidebar/cart-shopping.svg"  v-if="!isLinkActive('/services')"
                   style="margin-left:0; width: 20px; height: auto " alt="services"/>
              <img class="position-relative" src="../assets/img/itemsSidebar/cart-shoppingW.svg"  v-else
                   style="margin-left:0%; width: 20px; height: auto " alt="services"/>
              <i class="bx" />
              <span v-if="showTextLabels" class="router-link font-brand"
                    :class="{ active: isLinkActive('/services') }"
                    style="margin-left: -19%; font-size: 14px;">Nos services</span>
            </router-link>
          </li>
          <li class="position-relative" style="margin: -2% 0 0 0%" :class="{ active: isLinkActive('') }" >
            <router-link to="" :class="{ active: isLinkActive('') }">
              <img class="position-relative" src="../assets/img/itemsSidebar/propos.svg"
                   style="margin:-10% 5% -5% 0%; width: 20px; height: auto " alt="à propos"/>
              <i class="bx"/>
              <span v-if="showTextLabels" class="router-link font-brand"
                    :class="{ active: isLinkActive('') }"
                    style="margin-left: -19%; font-size: 14px; color: #0a0a0a "
              >À propos</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div>
        <i
            v-if="isExitButton"
            class="bx bx-log-out"
            id="log_out"
            @click.stop="$emit('button-exit-clicked')"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Sidebar',
  props: {
      isExitButton: {
        type: Boolean,
        default: false,
      },
        //! Menu settings
    isMenuOpen: {
      type: Boolean,
      default: true,
    },
    isUsedVueRouter: {
      type: Boolean,
      default: false,
    },
    menuLogo: {
      type: String,
      default: '',
    },
    menuIcon: {
      type: String,
      default: 'bxl-c-plus-plus',
    },
    isPaddingLeft: {
      type: Boolean,
      default: true,
    },
    menuOpenedPaddingLeftBody: {
      type: String,
      default: '250px',
    },
    menuClosedPaddingLeftBody: {
      type: String,
      default: '78px',
    },
    menuItems: {
      type: Array,
      default: () => [
        {
          link: '#',
          name: 'Accueil',
          tooltip: 'Accueil',
          icon: 'bx-grid-alt',
        },
        {
          link: '#',
          name: 'Informations personnelles',
          tooltip: 'User',
          icon: 'bx-user',
        },
        {
          link: '#',
          name: 'Sécurité',
          tooltip: 'Sécurité',
          icon: null,
        },
        {
          link: '#',
          name: 'Historique',
          tooltip: 'Historique',
          icon: 'bx-pie-chart-alt-2',
        },
        {
          link: '#',
          name: 'Périphériques',
          tooltip: 'Périphériques',
          icon: 'bx-cog',
        },
        {
          link: '#',
          name: 'Nos services',
          tooltip: 'Nos services',
          icon: 'bx-cart-alt',
        },
      ],
    },

    isSearch: {
      type: Boolean,
      default: true,
    },
    profileImg: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: '#11101d',
    },
    secondaryColor: {
      type: String,
      default: '#1d1b31',
    },
    homeSectionColor: {
      type: String,
      default: '#e4e9f7',
    },
    logoTitleColor: {
      type: String,
      default: '#fff',
    },
    iconsColor: {
      type: String,
      default: '#fff',
    },
    itemsTooltipColor: {
      type: String,
      default: '#e4e9f7',
    },
    searchInputTextColor: {
      type: String,
      default: '#fff',
    },
    menuItemsHoverColor: {
      type: String,
      default: '#fff',
    },
    menuItemsTextColor: {
      type: String,
      default: '#fff',
    },
    menuFooterTextColor: {
      type: String,
      default: '#fff',
    },
  },
  data() {
    return {
      isOpened: false,
      showTextLabels: true,
      isSmallScreen: false

    }
  },
  mounted() {
    this.isSidebarActive = !this.isSidebarActive;
    const currentURL = window.location.href;
    this.isOpened = true
    this.showTextLabels = true

    this.tooltipAttached()
  },
  computed: {
  },
  watch: {
    isOpened(val) {
      window.document.body.style.paddingLeft =
          this.isOpened && this.isPaddingLeft
              ? this.menuOpenedPaddingLeftBody
              : this.menuClosedPaddingLeftBody
    },
  },
  methods: {
    checkScreenSize() {
      if (window.innerWidth <= 1200){
        this.isOpened = true
      }
    },
    isLinkActive(link) {
      return this.$route.path === link;
    },
    tooltipAttached() {
      const tooltips = document.querySelectorAll('.tooltip')
      tooltips.forEach(tooltip => {
        document.body.appendChild(tooltip)
      })
      document.querySelectorAll('.tooltip').forEach(tooltip => {
        const targetID = tooltip.dataset.target
        const target = document.querySelector(`#${targetID}`)
        if (!target) return
        target.addEventListener('mouseenter', () => {
          const targetPosition = target.getBoundingClientRect()
          if (this.isOpened) return
          tooltip.style.top = `${targetPosition.top + window.scrollY}px`
          tooltip.style.left = `${
              targetPosition.left + targetPosition.width + 20
          }px`
          tooltip.classList.add('active')
        })
        target.addEventListener('mouseleave', () => {
          tooltip.classList.remove('active')
        })
      })
    },
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

span.active{
  color: white !important;
}
body {
  transition: all 0.5s ease;
}
.toggle-btn {
  display: none;
}


.name_job {
  margin-bottom: 5px;
}
.menu-logo {
  width: 30px;
  margin: 0 10px 0 10px;
}
.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 13%;
  height: 100%;
  min-height: min-content;
  overflow-y: auto;
  width: 100px;
  background:transparent;
  z-index: 99;
  transition: all 0.5s ease;
}
.sidebar.open {
  top: 13%;
  width: auto;
}
.router-link {
  text-decoration: none!important;
  color: #0D5286;
}
.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar .logo-details .logo_name {
  color: var(--logo-title-color);
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}
.active {
  background-color: #8EBE45!important;
  color: white !important;
  border-radius: 0px 30px 30px 0!important;
}
.sidebar .logo-details #btn {
  position: relative;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn {
  text-align: right;
}
.sidebar i {
//color: var(--icons-color);
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list {
  margin-top: 10px;
  /* margin-bottom: 60px; */
  /* height: 100%; */
  /* min-height: min-content; */
}
.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.tooltip {
  position: relative;
  z-index: 3;
  background: var(--items-tooltip-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.tooltip.active {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  /* top: 50%; */
  transform: translateY(25%);
}
.sidebar.open li .tooltip {
  display: none;
}
.sidebar input {
  font-size: 15px;
  color: var(--serach-input-text-color);
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: var(--secondary-color);
}
.bx{
  margin: -1% 0 -1% 0;
  color:#0D5286;
}
.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar.open .bx-search:hover {
  background: transparent;
  color: transparent;
}
.sidebar .bx-search:hover {
  background: transparent;
  color: transparent;
}
.sidebar li a {
  display: flex;
  /*height: 15%;*/
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: transparent;
}
.sidebar li a:hover {
  background: #d1f1a7;
  border-radius: 0px 30px 30px 0 ;
}
.sidebar li a .links_name {
  color: #0D5286;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #0D5286;
}
.sidebar li router-link {
  display: flex;
  height: 100%;
  width: 100%;
  text-align: start;
  color: #0D5286;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background:transparent;
}
.sidebar li router-link:hover {
  background: transparent;
}
.sidebar li router-link .links_name {
  color: var(--menu-items-text-color);
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar.open li router-link .links_name {
  opacity: 1;
  pointer-events: auto;
}
.sidebar li router-link:hover .links_name,
.sidebar li router-link:hover i {
  transition: all 0.5s ease;
  color: var(--bg-color);
}
.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar div.profile {
  position: relative;
  height: 60px;
  width: 78px;
  /* left: 0;
  bottom: 0; */
  padding: 10px 14px;
  background: var(--secondary-color);
  transition: all 0.5s ease;
  overflow: hidden;
}
.sidebar.open div.profile {
  width: 250px;
}
.sidebar div .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.sidebar div img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}
.sidebar div.profile .name,
.sidebar div.profile .job {
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
}
.sidebar div.profile .job {
  font-size: 12px;
}
.sidebar .profile #log_out {
  position: relative;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: var(--secondary-color);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}
.sidebar.open .profile #log_out {
  width: 50px;
  background: var(--secondary-color);
  opacity: 0;
}
.sidebar.open .profile:hover #log_out {
  opacity: 1;
}
.sidebar.open .profile #log_out:hover {
  opacity: 1;
  color: red;
}
.sidebar .profile #log_out:hover {
  color: red;
}
.home-section {
  position: relative;
  background: var(--home-section-color);
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text {
  display: inline-block;
  color: var(--bg-color);
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
.my-scroll-active {
  overflow-y: auto;
}
#my-scroll {
  overflow-y: auto;
  height: calc(100% - 60px);
}
#my-scroll::-webkit-scrollbar {
  display: none;
  /* background-color: rgba(255, 255, 255, 0.2);
  width: 10px;
  border-radius:5px  */
}
/* #my-scroll::-webkit-scrollbar-thumb{
  background-color: red;
  border-radius:5px
}*//*
#my-scroll::-webkit-scrollbar-button:vertical:start:decrement{
  display:none;
}
#my-scroll::-webkit-scrollbar-button:vertical:end:increment{
  display:none;
} */
@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}
</style>
