<template>
  <div>
    <h1>404</h1>
    <h3>Page non trouvée </h3>
    <p>Désolé, la page que vous recherchez n'existe pas.</p>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'NotFound'
};
</script>
