<template>
  <div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounded-5 shadow bg-white" >
      <div class="position-absolute">
        <img class="" src="../../../assets/img/dame_en_veste_jaune.svg" style="
          margin-top: -60%;
          margin-left: -160%;
            top: 652px;
            left: 247px;
            width: 274px;
            height: 268px;
            padding-left: 65%"
             alt="image du globe"/>
      </div>
      <img src="../../../assets/img/logo.png" alt="logo SYGALIN SAS"
           class="img-fluid " style="max-width: 60px;'' height: auto; margin-top: -3%;">
      <span class="mb-3 MTNBrighterSans-Bold-Sygalin " >
      <span class="col-sm-5" style="color: #0D5286; margin-left: -2%; margin-top: 7%; font-size: 200%">
           <b>SYGALIN SAS</b>
        </span >
        </span>
      <br/>
      <br/>
      <div class="small">
        <h5 class="mb-3 " style="color: #0D5286" ><b>Vous avez choisi la méthode <br/>de configuration par application.</b></h5>
        <br/>
        <h5 class="mb-3 MTNBrighterSans-Bold "
        ><b>Installer une application <br/>d’authentification</b></h5>
        <h5 class="mb-3 MTNBrighterSans-Bold" style="font-family: Raleway, regular; color: #0a0a0a"
        >Vous avez le choix parmi plusieurs <br/> Nous vous conseillons néanmoins les suivantes</h5><br/>
        <div class="d-flex align-items-start">
          <p class="" style="font-size: 14px; color: #0D5286"
          >MS Authentificator</p><br/>
        </div>
        <div class="d-flex align-items-start">
          <p class="" style="margin-top: -5%;font-size: 14px; color: #0D5286"
          >Authy</p><br/>
        </div>
          <div class="d-flex align-items-start">
          <p class="" style="margin-top: -5%;font-size: 14px; color: #0D5286"
          >Google Authentificator</p><br/>
          </div>
      </div>
      <div class="justify-content-end d-flex">
        <router-link to="/scan">
          <button type="submit" class="my-button">Suivant</button>
        </router-link>
      </div>
    </div>
    <div class=" col-sm-4 d-sm-none d-md-block d-lg-block" style="padding-left: 2%; margin-left: 5%">
      <img class="" src="../../../assets/img/securité%20dame.svg" style="background-color: transparent;
         max-width: 500px;'' height: auto;
            padding-top: 50%"
           alt="image de la dame cadenas"/>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name:'OtpApk',
  components: {
  },
  data() {
    return {
      otpValues: ['', '', '', '', '', ''],
      showCountdown: false,
      countdown: 7,
      isNull: false,
    };
  },
  methods: {
    handleClick(){
      // if(this.countdown > 0){
      //   this.isNull = true;
      // }else {

      this.$router.push('/dashboard')
      // if (length(this.otpValues)){
      // }
      // }
    },
    handleKeyDown(index, event) {
      if (event.key === 'Backspace') {
        if (index > 0 && this.otpValues[index] === '') {
          this.otpValues[index - 1] = '';
        }
      } else if (event.key.match(/[0-9]/)) {
        if (index < this.otpValues.length - 1) {
          this.otpValues[index + 1] = event.key;
        }
      } else {
        event.preventDefault();
      }
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(this.timer);
      }
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  }
};
</script>

<style>
.my-button {
  background-color: #8EBE45;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 15px;
}
.MTNBrighterSans-Bold-b{
  font-size: 14px;
  color: #0D5286;
}
.text-blue {
  color: #0D5286;
}
</style>
