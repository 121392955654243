<template>
  <div class="d-flex align-items-center justify-center bg-opacity-0.5">
    <!-- Overlay -->

    <!--      v-if="isOpen"-->
    <div   class="fixed flex items-center justify-center bg-opacity-0.5">
      <!-- Modal -->
      <div style="background-color: rgba(212, 234, 250, 0.9)!important; height: 400px; width: 300px"
           class="rounded-5 shadow-lg bg-opacity-0.5">
        <!-- Modal header -->
        <div class=" items-center mb-4">
          <h3 class="text-xl font-bold">@lil_aïby</h3>
          <div class="d-flex justify-content-center align-items-center">
            <div class="circle d-flex justify-content-center align-items-center">J</div>
          </div>

        </div>
        <!-- Modal content -->
        <p style="font-size: 16px">Bonjour Joseph!</p>
        <!-- Modal footer -->
        <div class="row d-flex col-sm-12 align-itels-center justify-content-center ">
          <div class="col-sm-6">
            <a href="/signin">
              <button @click="closeModal" class="position-relative btn btn-light rounded-start-4"
                      style="font-size: 12px" >
                <img src="../assets/img/add.svg"
                     class="col-sm-6"
                     style="background-color: transparent; height: 20px; width: auto" alt="LOGO +">
                Ajouter un compte</button>
            </a>
          </div>
          <div class="col-sm-6">
            <a href="/signin">
              <button @click="closeModal" class="btn btn-light position-relative rounded-end-4"
              style="font-size: 13px; padding: 12% 10% 12% 6%;"
              >Déconnexion
                <img src="../assets/img/out.svg"
                     style="background-color: transparent; height: 10px; width: auto" alt="LOGO Sign out">
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "userModal",
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    }
  }
}
</script>

<style >
.circle {
  align-self: center;
  width: 100px;
  height: 100px;
  background-color: #0C5286;
  border-color: #6B982F;
  font-size: 50px;
  color: white;
  border-radius: 100%; /* Un rayon de 50% de la largeur/hauteur fait un cercle */
}
</style>