<template>
    <div class="shadow">
      <Navbar/>
    </div>
    <div class="col-sm-12 ">
      <div class="col-sm-12 position-relative d-flex justify-content-center align-items-center"
           >
        <div class="col-sm-5" style="margin-top: -17%">
          <img src="../assets/img/Welcome.svg" style="max-width: 100%; height: auto;" alt="image d'accueil"/>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div class="text-start col-sm-7">
          <p style="font-size:40px; color:#315382;" >Découvrez nos centres d'intérêt</p>
          <p style=" color: #8A92A6;font-size: 20px; margin-top: -3%"> Explorez l'univers SYGALIN!</p>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div class="col-sm-7">
         <slides/>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div class="text-start col-sm-7">
          <h4 class="">Nos services</h4>
          <p style="font-family: Raleway, regular; color: #8A92A6;font-size: 15px; margin-top: -2%"> Découvrez notre univers!</p>
          <div class="row col-sm-12">
            <div class="row col-sm-12 d-flex justify-content-start">
              <div class="col-sm-3 position-relative" >
                <img class=" rounded-5" src="../assets/img/img-carrousel.jpg"
                     style="max-width: 100%;''high:auto" alt="image du service"/>
                <div class=" col-sm-3 position-absolute" style="margin-top: -20%">
                  <img src="../assets/img/img-carrousel.jpg"
                       style="
                width: 50px;
                height: auto;
                max-width: 100%;''high:auto" alt="logo du service"/>
                </div>
                <p style="padding-top: 06%"><b>Asso Ease</b></p>
              </div>
              <div class="col-sm-3 position-relative"   >
                <img class=" rounded-5" src="../assets/img/img-carrousel.jpg"
                     style="max-width: 100%;''high:auto" alt="image du service"/>
                <div class=" col-sm-3 position-absolute" style="margin-top: -20%">
                  <img src="../assets/img/img-carrousel.jpg"
                       style="max-width: 100%;''high:auto" alt="logo du service"/>
                </div>
                <p style="padding-top: 06%"><b>Asso Ease</b></p>
              </div>
              <div class="col-sm-3 position-relative ">
                <img class=" rounded-5" src="../assets/img/img-carrousel.jpg"
                     style="max-width: 100%;''high:auto" alt="image du service"/>
                <div class=" col-sm-3 position-absolute rounded-3 bg-black" style="margin-top: -20%">
                  <img src="../assets/img/logo/SPA%20SYNC%20SOLO.png"
                       style="max-width: 100%;''high:auto" alt="logo du service"/>
                </div>
                <p style="padding-top: 06%"><b>SPA SYNC PRO</b></p>
              </div>
              <div class="col-sm-3 position-relative" >
                <img class=" rounded-5" src="../assets/img/img-carrousel.jpg"
                     style="max-width: 100%;''high:auto" alt="image du service"/>
                <div class="position-absolute " style="
                width: 50px;
                height: auto;
                margin-top: -20%">
                   <img class="bg-black rounded-3" src="../assets/img/logo/solo%20Ease.png"
                        style="max-width: 100%;''high:auto" alt="logo du service"/>
                </div>
                <p style="padding-top: 06%"><b>Asso Ease</b></p>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="col-sm-12">
        <div class="footer-container">
          <Footer/>
        </div>
      </div>
    </div>
  <div>
    <floating-botton/>
  </div>
</template>

<script>

import Footer from "@/components/Footer.vue";
import Navbar from "@/components/navMarket.vue";
import Slides from "@/components/slides.vue";
import FloatingBotton from "@/components/floatingBotton.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'MarketPlace',
  components: {
    FloatingBotton,
    Slides,
    Navbar,
    Footer,
  },
  data() {
    return {
      images: [
      ]
    };

  }
}
;
</script>

<style >
.carousel-item {
  text-align: center;
}
</style>