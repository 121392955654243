<template>
  <nav class="navbar bg-body-tertiary fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS"
             class="img-fluid position-relative " style="max-width: 50px; height:auto; margin: 5% 0 0 0">
        <a class="router-link font-brand" style="margin: 0 0 0 0; font-size: 15px;color: #134973;" href="#">My Sygalin
        </a>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
            <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS"
                 class="img-fluid position-relative " style="max-width: 50px; height:auto; margin: 5% 0 0 0">
            <a class="router-link font-brand" style="margin: -20% 0 0 0; font-size: 15px;color: #134973;" href="#">Sygalin Market
            </a>
          </h5>
        </div>
        <div class="offcanvas-body">
          <sidebar style="margin: 20% 0 0 0"/>
          <form class="d-flex mt-3" role="search">
            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-outline-success" type="submit">Search</button>
          </form>
        </div>
      </div>
    </div>
  </nav>

</template>
<script>
import Sidebar from "@/components/SideBar.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name:'test',
  components: {
    Sidebar
  },
};
</script>

<style>
body{
  background-color: #EFEFEF;
}
.container{
  width:400px;
  margin:50px auto;
  background: white;
  padding:10px;
  border-radius: 3px;
}
h1{
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
}
.frmField{
  background-color:white;
  color:#495057; line-height: 1.25;
  font-size: 16px;
  border:0;
  padding: 10px;
  border:1px solid #dbdbdb;
  border-radius: 3px;
  width:90%;
}
.frmLabel{
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.frmValidation{
  font-size: 13px;
}
.frmValidation--passed{
  color:#717b85;
}
.frmIcon{
  color:#EB0029;
}
.frmValidation--passed .frmIcon{
  color:#0fa140;
}

.howToBuild{
  text-align:center;
  color:purple;
}
.howToBuild a{
  color:grey;
  font-weight:bold;
  text-decoration:none;
  text-transform:uppercase;
}
</style>