<template class="screen">
  <test class="side"/>
  <user-navbar class="sideNav"/>
  <sidebar class="sideNav"/>
  <div class="container-fluid col-sm-10 align-item-start" >
    <div class="text-start ">
      <h1 class="text-start co-sm-12 font-brand" style="color: #0D5286">Nos services</h1>
      <h5 class="" style="font-family: Raleway, regular!important; color: #8A92A6 "
      >Parcourez les solutions que nous avons mis sur pieds pour vous faciliter la vie</h5>
    </div>
    <div class="row col-sm-12">
        <div class="card bg-white col-sm-4 rounded-4">
          <div class="card-body d-flex">
            <div class="row col-sm-10">
              <div class="card-body items-start">
                <img src="../../assets/img/logo/solo%20Ease.png" style="height: 15px; height: auto"/>
                <p class="text-start" style="font-size: 13px" >
                  Le service par excellence de gestion d’associations
                </p>
              </div>
             </div>
          </div>
        </div>
        <div class="card bg-white col-sm-4 rounded-4">
          <div class="card-body justify-content-center">
            <div class="row col-sm-12">
              <div class="card-body">
                <img src="../../assets/img/logo/SPA%20SYNC%20SOLO.png" style="height: 40px; height: auto"/>
                <p class="text-start" style="font-size: 13px">
                  Le service par excellence de gestion d’associations
                </p>
              </div>
             </div>
          </div>
        </div>
        <div class="card bg-white col-sm-4 rounded-4">
          <div class="card-body justify-content-center">
            <div class="row col-sm-10">
              <div class="card-body justify-content-center description  position-relative">
                <img class="img " src="../../assets/img/logo/solo%20Ease.png" style="height: 20px; height: auto"/>
                <p class="text-start" style="font-family: Raleway, regular!important; font-size: 13px" >
                  Le service par excellence de gestion d’associations
                </p>
                  <div  class="d-none ">
                    <p> Lorem ipsum dolor </p>
                  </div>
              </div>
             </div>
          </div>
        </div>
    </div>
  </div>
  <floating-botton/>
<!--  <div style="margin: 27% 0 0 -2%">-->
<!--    <Footer style="margin:0"/>-->
<!--  </div>-->
  <Footer/>
  <floating-botton/>
</template>

<script>
import UserNavbar from "@/components/UserNavbar.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/SideBar.vue";
import FloatingBotton from "@/components/floatingBotton.vue";
import Test from "@/view/signup/responsiveBar.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "history",
  components: {
    Test,
    FloatingBotton,
    Footer,
    Sidebar,
    UserNavbar
  },
  data(){
    return{
      show:false
    }
  }
}
</script>

<style >

<style scoped>
.page-with-footer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}
.dropup-container {
  position: relative;
  transition: all 0.3s ease;
}

.card-body.position-relative:hover p {
  transform: translateY(-10px);
  color: blue;
}
.dropup-container:hover {
  bottom: 140px;
}


.dropup {
  transition: all 0.3s ease;
}


.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}


.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.dropup:hover {
  transform: translateY(-20px) scale(1.1);
}
@media screen and (max-width: 1278px) {
  .sideNav{
    display: none !important;
    max-width: 0!important;
  }
  .hide-img{
    display: none !important;
    max-width: 0!important;
  }
  body{
    padding-left: 0 !important;
  }
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.dropup:hover .dropdown-menu {
  display: block;
}

.logo {
  margin: 0 0 0 2%;
  width: 30px;
  height: auto;
}


.menu-logo {
  margin: 0 0 0 2%;
  height: 10px;
  width: auto;
}

.heart-icon {
  height: 20px;
  width: auto;
  color: #0D5286;
  position: absolute;
  margin: -2% -10% 0% 20%;
}
.dropup-container {
  position: fixed;
  bottom: 2%;
  left: 80%;
  transform: translateX(-50%);
  transition: all 0.3s ease;
}

.dropup-container:hover {
  bottom: 140px;
}

.dropup-container:hover .head {
  color: #0D5286;
}

.dropup {
  transition: all 0.3s ease;
}

.dropup:hover {
  transform: translateY(-20px) scale(1.1);
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.dropup:hover .dropdown-menu {
  display: block;
}
</style>