<template>
  <Carousel :autoplay="5000" :items-to-show="2"  :snap-align="start" :items-to-control="2">
    <Slide v-for="url in imageUrls" :key="url">
      <div class="carousel__item" >
        <div class="col-sm-12" >
          <img :src="url" alt="Image de publicitaire" class="position-relative" style="width: 100%;"/>
        </div>
      </div>
    </Slide>
    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Pagination, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'slides',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data() {
    return {
      imageUrls: [
        require('../assets/img/img-carrousel.jpg'),
        require('../assets/img/img-carrousel.jpg'),
        require('../assets/img/img-carrousel.jpg'),
        require('../assets/img/img-carrousel.jpg'),
        require('../assets/img/img-carrousel.jpg'),
        require('../assets/img/img-carrousel.jpg'),
        require('../assets/img/img-carrousel.jpg')
      ]
    }
  }
})
</script>

<style scoped>
.carousel__item {
  min-height: 200px;
/*width: 100%;*/
  background-color: transparent;
/*color: var(--vc-clr-white);*/
  border: 3px solid #0D5286;
  font-size: 20px;
  border-radius: 8px;
/*display: flex;
//justify-content: center;
//align-items: center;*/
}

.carousel__slide {
  padding: 10px;

}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  /*padding: -100px;*/
  border: 3px solid #0D5286;
}
</style>
