<template>
  <transition name="slide-fade" mode="out-in">
    <router-view/>
  </transition>
</template>

<script>

export default {
  name: 'App',
    data(){

    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@font-face {
  font-family: 'Mimolette-Bold';
  src: url('@/assets/fonts/Mimolette-Bold.woff2') format('woff2'),
  url('@/assets/fonts/Mimolette-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Mimolette-Bold';
  src: url('../public/Mimolette-Bold.woff2') format('woff2'),
  url('../public/Mimolette-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'Mimolette-Bold', sans-serif;
}


#app{
  background-color: #EAEDED;
  font-size: 10px;
  text-align: center;
  margin-top: 90px;
  align-items: center;
  justify-content: center;
  h1{
    text-transform: none!important;
  }
}

.font-brand {
  font-family: "Mimolette-Bold";
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

</style>
