import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VOtpInput from "vue3-otp-input";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import VueCarousel from 'vue-carousel';
import '@fortawesome/fontawesome-free/css/all.css';
import vueAwesomeSidebar from 'vue-awesome-sidebar';
import 'vue-awesome-sidebar/dist/vue-awesome-sidebar.css';
import VueCookies from 'vue3-cookies';
import Vuelidate from 'vuelidate';
import tooltip from './directives/v-tooltip';
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';
import './assets/style.css';


library.add(fas);
library.add(faCaretDown)

const app = createApp(App);
app.use(VueCarousel);
app.use(VueCookies, { expires: '1d' })

app.directive('tooltip', VTooltip.VTooltip)
app.directive('close-popover', VTooltip.VClosePopover)
app.component('v-popover', VTooltip.VPopover)
app.directive('tooltip', tooltip);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(vueAwesomeSidebar)
app.use(VTooltip)
app.config.productionTip = false;
app.config.globalProperties.$axios = axios
app.component('v-otp-input', VOtpInput)
app.use(router);
app.use(Vuelidate);
// app.use(vueAwesomeSidebar);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(VueCookies);

app.mount('#app');
