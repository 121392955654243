<template>
  <div class="container-fluid col-sm-11 d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounde bg-white shadow align-content-start responsive" style="margin-top: -9%" >
      <div class="col-sm-12 align-content-start">
        <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS" class="img-fluid"
             style="max-width: 60px; height: auto;margin-top: -3%;">
        <span class="col-sm-5" style="color: #0D5286; margin-left: -2%; margin-top: -0%; font-size: 200%"
        ><b>SYGALIN SAS</b></span>

      </div>
      <br/>
      <br/>
      <br/>
      <div class="small">
        <h4 class="mb-4 " style="color: #8EBE45"><b>Votre compte <br/>a été créé avec succès!</b></h4>
        <br/>
        <p class="" style="margin-top: -20px; font-size: 17px; color: #0D5286 "
        >Il est recommandé <br/>de sécuriser votre compte <br/>avec un deuxième <br/>facteur d'authentification.</p>
      </div>
      <br/>
      <br/>
      <div class="row">
        <div class="align-items-start d-flex justify-content-start">
          <router-link to="" @click="ignored" class="router-link" style="margin-top: 8%">
            <p class="" style="color: #1692E7; font-size: 14px ">Ignorer</p>
          </router-link>
        <div class="col-md-10 d-flex justify-content-end ">
          <button type="submit" @click="this.$router.push('/settings')" class="my-button"><b>Configurer</b></button>
        </div>
        </div>
      </div>
    </div>
    <div style="margin-left: 10%;" class="img-fluid col-sm-6 d-none d-sm-none d-md-block d-lg-block">
      <img class="img-fluid" src="../../assets/img/victoria.svg"
           style="max-width: 80%; height: auto; margin-top: 18%"
           alt="image d'un homme qui monte un échelle">
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "AccountcComplet",
  methods:{
      ignored(){
        const storedQuery = JSON.parse(localStorage.getItem('redirect'));
        console.log(storedQuery)
        this.$router.push({ name: 'SignIn', query: storedQuery });
      }
  }
}
</script>

<style scoped>
.router-link {
  text-decoration: none;
}
.p{
  font-size: 15px;
  color: #1c7bc5;
}
.o{
  font-size: 15px;
}
</style>