<template class="screen">
  <test class="side"/>
  <user-navbar class="sideNav"/>
  <sidebar class="sideNav"/>
  <div class="container-fluid col-sm-10 align-item-start" >
    <div class=" text-start">
      <h1 class="text-start font-brand" style="color: #0D5286; ">Sécurité</h1>
      <h5 class="col-sm-8" style="font-family: Raleway, regular!important; color: #8A92A6 "
      >Paramètres et recommandations pour vous aider à protéger votre compte</h5>
    </div>
    <div class="row col-sm-8" style="margin: 2% 0% 0% 0%;">
      <div class="col-sm-12 row" style="margin-left: 0">
      <div class="col-sm-6" style="margin-left: 0">
        <div class="card bg-white rounded-4">
          <div class="card-head">
            <p class="font-brand" style="color: #0D5286; font-size: 17px; margin: 5% 0 0 0"> Configuration par SMS</p>
          </div>
            <div class="card-body ">
              <div class="col-sm-12 justify-content-center">
                  <img src="../../assets/img/methods/sms.svg" style="height: 100px; width: auto;" alt="logo de sms">
              </div>
              <p class="font-brand" style="font-size:25px; color: #6B982F; margin-bottom: 20%">Configuré</p>
            </div>
          </div>
          </div>
      <div class="col-sm-6" style="margin-left: 0">
        <div class="card bg-white rounded-4">
          <div class="card-head">
            <p class="font-brand" style="color: #0D5286; font-size: 17px; margin: 5% 0 0 0"> Configuration par E-mail</p>
          </div>
            <div class="card-body ">
              <div class="col-sm-12 justify-content-center">
                  <img src="../../assets/img/methods/mail-unread.svg" style="height: 100px; width: auto;" alt="logo L'E-mail ">
              </div>
              <p class="font-brand" style="font-size:17px; color: #FC1300">Non Configurer</p>
              <button class="btn rounded-pill" style=" background-color: #6B982F; font-size:16px; color: white">Configuré</button>
            </div>
          </div>
          </div>
        </div>
      </div>
    <div class="row col-sm-8" style="margin: 2% 0% 0% 0%">
      <div class="col-sm-12 row" style="margin-left: 0">
      <div class="col-sm-6" style="margin-left: 0">
        <div class="card bg-white rounded-4">
          <div class="card-head">
            <p style="color: #0D5286; font-size: 17px; margin: 5% 0 0 0"> Configuration par WhatsApp</p>
          </div>
            <div class="card-body ">
              <div class="col-sm-12 justify-content-center">
                  <img src="../../assets/img/methods/logo-whatsapp.svg" style="height: 100px; width: auto;" alt="logo de WhatsApp">
              </div>
              <p class="font-brand" style="font-size:25px; color: #6B982F">Configuré</p>
            </div>
          </div>
          </div>
      <div class="col-sm-6" style="margin-left: 0">
        <div class="card bg-white rounded-4">
          <div class="card-head">
            <p class="font-brand" style="color: #0D5286; font-size: 17px; margin: 5% 0 0 0"> Configuration par OTP</p>
          </div>
            <div class="card-body ">
              <div class="col-sm-12 justify-content-center">
                  <img src="../../assets/img/methods/qr-code.svg" style="height: 100px; width: auto;" alt="logo OTP">
              </div>
              <p class="font-brand" style="font-size:25px; color: #6B982F">Configuré</p>
            </div>
          </div>
          </div>
        </div>
      </div>
    <div class="d-flex align-items-end sideNav" style="margin-top: -10%">
      <img src="../../assets/img/Groupe237.svg" class="position-absolute"
           style="margin-left: 45%;
                    width: auto;
                    max-height: 70%;
                    margin-top: -100%;"
           alt="dame qui souhaite la bienvenue"/>
    </div>
  </div>
  <Footer/>
  <floating-botton style="margin: 0% 0% 2% 0%"/>
<!--  <div >-->
<!--    <Footer style="margin: 0% 0 0 -0%"/>-->
<!--  </div>-->
</template>

<script>
import UserNavbar from "@/components/UserNavbar.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/SideBar.vue";
import FloatingBotton from "@/components/floatingBotton.vue";
import Test from "@/view/signup/responsiveBar.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "security",
  components: {
    Test,
    FloatingBotton,
    Footer,
    Sidebar,
    UserNavbar
  }
}
</script>

<style scoped >
@media screen and (max-width: 1278px) {
  .sideNav{
    display: none !important;
    max-width: 0!important;
  }
  .hide-img{
    display: none !important;
    max-width: 0!important;
  }
  body{
    padding-left: 0 !important;
  }
}
</style>