<template>
  <div class="container-fluid d-flex justify-content-center align-items-center" style="margin-top: 13%">
    <div class="col-sm-4 p-4 rounde shadow bg-white responsive"  >
      <div class="col-sm-12 " >
        <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS"
             class="img-fluid position-relative" style="max-width: 60px;'' height: auto; margin-top: -3%;">
        <span class="mb-3 MTNBrighterSans-Bold-Sygalin " style="margin-left: -2px">
          <b style="font-family: 'MTNBrighterSans-Bold', sans-serif; font-size: 20px;
              margin-left: 0; padding-top: 2%; color: #0D5286">SYGALIN SAS</b>
        </span>
      </div>
      <br/>
      <div class="small">
        <h3 class="mb-4 MTNBrighterSans-Bold" style="font-size: 22px;" ><b>Choisissez votre login!</b></h3>
        <p class="" style="margin-top: -10px; font-size:.9375rem;">Saisissez un login!</p>
<!--        <p class="MTNBrighterSans-Bold-m" style="margin-top: -20px;">Saisissez un login ou créez-en un!</p>-->
      </div>
      <br/>
      <br/>
      <br/>
      <form @submit.prevent="handleSubmit">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <div class="custom-radio text-start">
<!--            <div class="form-check">-->
<!--              <input @click="val" class="form-check-input" type="radio"-->
<!--                     :disabled="isLoading" name="firstLogin" id="firstLogin"-->
<!--                     value="firstLogin" v-model="selectedOption" required/>-->
<!--              <label class="form-check-label" for="firstLogin">-->
<!--                <b class="MTNBrighterSans-Bold">First Login</b>-->
<!--              </label>-->
<!--            </div>-->
<!--            <div class="form-check">-->
<!--              <input @click="val" class="form-check-input" type="radio"-->
<!--                     :disabled="isLoading" name="secondLogin" id="secondLogin"-->
<!--                     value="secondLogin" v-model="selectedOption" required />-->
<!--              <label class="form-check-label" for="secondLogin">-->
<!--                <b class="MTNBrighterSans-Bold">Second Login</b>-->
<!--              </label>-->
<!--            </div>-->
<!--            <div class="form-check">-->
<!--              <input @click="val" class="form-check-input" type="radio" name="exampleRadios"-->
<!--                     id="exampleRadios3" value="customLogin"-->
<!--                     :disabled="isLoading"-->
<!--                     v-model="selectedOption" required-->
<!--              />-->
<!--              <label class="form-check-label" for="exampleRadios3">-->
<!--                <b class="MTNBrighterSans-Bold">Créez votre propre login</b>-->
<!--              </label>-->
<!--              <div v-if="selectedOption === 'customLogin'">-->
<!--              <div>-->
                <input type="text" class="form-control rounded-pill" @input="typing" v-model="login"
                       :disabled="isSubmitting" placeholder="Votre propre login" style="size: 30px" required/>
                <span class="text-danger" v-if="checkLogin" style="font-size: 14px">
                  Veuillez renseigner votre login
                </span>
                <span class="text-danger" v-if="isUsed" style="font-size: 14px">
                  Ce login est déjà utilisé. Essayez un autre login.
                </span>
                <span class="text-danger" v-if="usernameError" style="font-size: 14px">
                  Le login doit avoir au moins 8 caractères
                </span>
                <span class="text-danger" v-if="netError" style="font-size: 14px">
                  Connexion à internet instable. Veuillez réessayer!
                </span>
              </div>
            </div>
<!--            <span  class="text-danger" v-if="isSelected" style="font-size: 12px" >-->
<!--                Veuillez sélectionner un login-->
<!--              </span>-->
<!--          </div>-->
<!--        </div>-->
        <div class="col-md-12 d-flex justify-content-end">
          <button type="submit" class="my-button" style="margin-top: 30%">
            <span v-if="!isSubmitting" id="button-text">Suivant</span>
            <div v-else class="spinner" id="spinner"></div>
          </button>
        </div>
      </form>
    </div>
    <div class="img-fluid col-6 d-none d-sm-none d-md-block d-lg-block" >
      <div style=" margin-left: 3%;">
        <img class="img-fluid" src="../../assets/img/E-mail.svg"
             style="max-width: 80%; height: auto; margin-top: -100px " alt="image du mail">
      </div>
    </div>
  </div>
</template>


<script>
import {baseUrl} from "@/store/baseUrl";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "next",
  components: {
  },
  data() {
    return {
      isSelected: true,
      checkLogin: false,
      isUsed:false,
      exist:false,
      login:'',
      netError:'',
      usernameError: false,
      selectedOption: null,
      isSubmitting:false,
      timer: null
    };
  },
  created() {
    const username = localStorage.getItem('username');
    if (username) {
      this.login = username;
    }
  },
  methods: {
    // val(){
    //   this.isSelected = false;
    // },
    typing(){
      this.isUsed = false;
      this.checkLogin = false;
      this.usernameError = false
    },
      async handleSubmit() {
        const controller = new AbortController();
        const signal = controller.signal;
        const timeout  = setTimeout(() => {
          controller.abort();
          this.isSubmitting = false;
          this.netError = true
        }, 30000);
        try {
          // if(this.selectedOption === 'customLogin' ){
            if (this.login === ''){
              this.checkLogin = true
            }else if (this.login.length < 8) {
              this.usernameError = true
            }else{
             const data={
               username:this.login
             }
              this.isSubmitting = true;
            localStorage.setItem('username', this.login );
            const response = await fetch(`${baseUrl}/account/v1/check-username/` ,
                {
                  signal,
                  method: 'post',
                  headers: {
                    // 'Authorization': 'tokken',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                  },
                  body: JSON.stringify(data)
                  // signal: signal
                });
            this.respond = await response.json();
              if (this.respond !== null){
                clearTimeout(timeout);
              }
              console.log(this.respond);
            if (this.respond.status === 400){
              this.isSubmitting = false;
              this.isUsed = true;
            }
            if (this.respond.status === 200){
              const session_id = this.respond.data.session_id;
              localStorage.setItem('session_id', session_id);
              this.isSubmitting = false;
              this.$router.push('/user')
            }else if (this.respond.status === 403){
            this.$router.push('/error');
          }
            else if (this.respond.status !== 200){
              this.isSubmitting = false;
              this.isUsed = true
            }
              this.isSubmitting = false;
          }
            // }
        }catch (e) {
          this.isSubmitting = false;
          }
        },
        // this.$router.push('/user')
   },
mounted() {
    localStorage.removeItem('username');
  }
};
</script>

<style>
.form-check-input[type="radio"]:checked {
  background-color: #007bff;
  border-color: #007bff;
}
.rounde {
  border-radius: 40px;
}
.MTNBrighterSans-Bold {
  font-family: MTNBrighterSans-Bold, sans-serif;
  color: #0D5286;
}
.MTNBrighterSans-Bold-m{
  font-size: 13px;
  font-family: Raleway, regular;
}

.MTNBrighterSans-Bold-Sygalin {
  font-family: MTNBrighterSans, sans-serif;
  font-size: 11px;
  color: #0E5386;
}
.MTNBrighterSans-Bold {
  font-family: MTNBrighterSans, sans-serif;
  font-size: 15px;
  color: #DFE1E6;
}
.my-button {
  background-color: #0056b3;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.my-button:hover {
  background-color: darkblue;
}
.custom-radio .form-check-input:checked + .form-check-label::before {
  background-color: darkblue;
  border-color: darkblue;
}

.custom-radio .form-check-input:checked + .form-check-label::after {
  color: #4a7ea4;
}

.custom-radio .form-check-input {
  border-color: #4a7ea4;
}

.custom-radio .form-check-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 128, 0.25);
}

.custom-radio .form-check-input:active {
  background-color: #4a7ea4;
  border-color: #4a7ea4;
}

.custom-radio .form-check-label {
  color: #4a7ea4;
}
.mirrored-image {
  transform: scaleX(-1);
}


</style>
