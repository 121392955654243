<template>
  <div class="d-flex align-items-center justify-center bg-opacity-0.5">
    <!-- Overlay -->

    <!--      v-if="isOpen"-->
    <div   class="fixed flex items-center justify-center bg-opacity-0.5">
      <!-- Modal -->
      <div style="background-color: #6B982F !important; height: 300px; width: 250px; margin: 0 5% 0 5%;
                  padding-top: 4%"
           class="rounded-5 shadow-lg bg-opacity-0.5">
        <div style="background-color: white!important; height: 290px; width: 230px; margin: 0 5% 0 4%;
                  padding-top: 5%"
             class="rounded-4 shadow-lg bg-opacity-0.5">
          <!-- Modal header -->
          <div class="d-flex text-start mb-5">
            <img src="../assets/img/logo/solo Ease.png" style="height: 30px; width: auto">
            <span class=""  style="font-family: Raleway, regular!important; font-size: 14px;
            color: #0D5286">Le service par excellence de gestion d’associations
            <hr class="position-relative" style="color: #8EBE45; margin: 0 0 0 -15%"/>
            </span>
          </div>
          <div class="d-flex text-start mb-5" style=" margin: -17% 0 0 0">
            <img src="../assets/img/logo/SPA SYNC SOLO.png" style="height: 30px; width: auto">
            <span class=""  style="font-family: Raleway, regular!important;font-size: 14px;
            color: #0D5286">La magie du spa à l’ère du numérique
            <hr class="position-relative" style="color: #8EBE45; margin: 0 0 0 -15%"/>
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "servicesModal",
  data() {
  }
}
</script>

<style >
.circle {
  align-self: center;
  width: 100px;
  height: 100px;
  background-color: #0C5286;
  border-color: #6B982F;
  font-size: 50px;
  color: white;
  border-radius: 100%;
}
</style>