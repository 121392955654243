<template>
  <div class="footer">
    <div class="font-brand justify-content-sm-12-end" style="background-color: #0C5286;
  margin: 0% 0% -1% -30%;
  text-align: right;"
  >
    <p class="font-brand" style="color: white">&copy; Copyright: SYGALIN SAS</p>
  </div>
</div>

</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  data() {
    return {
      currentYear: null
    };
  },
  mounted() {
    this.getCurrentYear();
  },
  methods: {
    getCurrentYear() {
      const currentDate = new Date();
      this.currentYear = currentDate.getFullYear();
    }
  }
}
</script>

<style>

.footer {
  background-color: #0C5286;
  text-align: right;
  padding: 0.8rem;
  width: 100%;
  height: 0%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

</style>