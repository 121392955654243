<template>
  <div class="overlay">
    <div class="centered-div">
      <img src="../../../src/assets/img/logo/cross.svg"
           style="height: 120px; width: auto" alt="Image d'echec de création de compte">
      <p>Votre session a expiré. Veuillez recommencer!</p>
      <button class="btn btn-danger" @click="restart">Recommencer</button>
    </div>
  </div>
</template>

  <script>
    export default {
      name: 'CenteredOverlay',
      methods: {
        restart(){
          this.$router.push('/signup');
        }
      }
    };
  </script>

  <style scoped>
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }

    .centered-div {
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      color: red;
      font-size: 20px;
    }
  </style>