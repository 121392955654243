<template>
  <div>
<!--    <button @click="show = !show">Toggle</button>-->
    <Transition name="slide-fade">
<!--      <p  v-if="show">hello</p>-->
    <div v-if="show" class="container-fluid d-flex justify-content-center align-items-center">
      <div class="col-sm-4 p-4 rounded-5 bg-white shadow">
        <div class="col-sm-12 d-flex align-content-start">
          <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS" class="img-fluid"
               style="max-width: 60px; height: auto;">
          <span class="col-sm-5" style="color: #0D5286; margin-left: -5%; margin-top: 3%;
        font-family: MTNBrighterSans-Bold;
         font-size: 200%"
          ><b>SYGALIN SAS</b></span>
          <div class="position-relative">
            <div class="col-7">
              <img class="img-fluid" src="../../assets/img/femme_violette.svg"
                   style="width: 100%;
                 height: auto;
                 margin-left: 100%;
                 margin-top: -90%"
                   alt="La dame en qui manipule son pc"/>
            </div>
          </div>
        </div>
        <br/>
        <div class="small">
          <h4 class="mb-4" style="color: #0D5286" ><b>Modifier votre nom!</b></h4>
          <p class="MTNBrighterSans-Bold-ligh" style="margin-top: -25px">Veuillez renseigner votre nouveau nom!</p>
        </div>
        <div>
          <img class="position-relative" src="../../assets/img/question_tag.svg" style="
          background: none;
          margin-bottom: -100px;
          width: 40%; height: auto">
        </div>
        <br/>
          <div class="position-absolute">
            <img class="" src="../../assets/img/homme_gros.svg" style="
          margin-top: -20%;
          margin-left: -105%;
            width: 274px;
            height: 268px;
            padding-top: 50%"
                 alt="image d'un homme gros"/>
          </div>
          <div class="row" style="margin-top: 10%">
            <div class="col-sm-12 d-flex justify-content-end">
              <button type="reset" class="my-button bg-danger" style="margin: 20% 0 0 -50%">Annuler</button>
              <router-link to="/information">
              </router-link>
              <router-link to="/information" style="margin: 20% 0 0 0">
                <button type="submit" class="my-button" >Suivant</button>
              </router-link>
            </div>
          </div>
      </div>
      <div class="img-fluid col-sm-5 d-none d-sm-none d-md-block d-lg-block">
        <img class="img-fluid" src="../../assets/img/Globe.svg"
             style="max-width: 100%; height: auto;  margin-left: 50px;" alt="image du globe"/>
      </div>
    </div>
    </Transition>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name:'testModal',
  components: {

  },
  data(){
    return{
      show:false
    }
  }
};
</script>
<style>
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}
</style>
