<template>
  <div class="container-fluid d-flex justify-content-center align-items-center" style="margin-top: -12%">
    <div class="col-sm-4 p-4 rounde shadow bg-white responsive"  >
      <div class="col-sm-4 " >
        <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS"
             class="img-fluid position-relative " style="max-width: 30px;'' height: auto;">
        <span class="mb-3 MTNBrighterSans-Bold-Sygalin " style="margin-left: -2px">
          <b>SYGALIN SAS</b>
        </span>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <div class="small">
        <h4 class="mb-4 MTNBrighterSans-Bold" style="color: #8ebe45"><b>Votre compte <br/>a étét créé avec succes!</b></h4>
        <br/><p class="mb-4 MTNBrighterSans-Bold"
                style="font-size: 13px">
        <b>Il est recommandé <br/>de sécuriser votre compte <br/>avec un deuxième <br/>facteur de configuration</b></p>
      </div>
      <br/>
      <div class="col-sm-12">
          <div class=" d-flex justify-content-end ">
            <button type="submit" class="my-button" style="background-color: ##eaeded">Configurer</button>
          </div>
        <div class="text-start" style="margin-top: -3%">
          <router-link to="/signin" class="router-link">
            <span class="text-primary">Ignorer</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class=" col-sm-5 d-sm-none d-md-block d-lg-block">
      <img class="" src="../../assets/img/victoria.svg" style="background-color: transparent;
         max-width: 700px;'' height: auto; margin-left: 60px; padding-top: 30%"
           alt="image d'un homme sur une échelle "/>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "success"
}
</script>

<style scoped>
.router-link {
  text-decoration: none;
}
.p{
  font-family: MTNBrighterSans, sans-serif;
  font-size: 19px;
  color: #1c7bc5;
}
.o{
  font-family: MTNBrighterSans, sans-serif;
  font-size: 15px;
}

</style>