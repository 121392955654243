// src/directives/v-tooltip.js
export default {
    beforeMount(el, binding) {
        const tooltipSpan = document.createElement('span');
        tooltipSpan.className = 'tooltip-text';
        tooltipSpan.innerText = binding.value;
        el.appendChild(tooltipSpan);

        el.onmouseenter = () => {
            tooltipSpan.style.opacity = 1;
            tooltipSpan.style.visibility = 'visible';
        };

        el.onmouseleave = () => {
            tooltipSpan.style.opacity = 0;
            tooltipSpan.style.visibility = 'hidden';
        };
    },
    unmounted(el) {
        el.onmouseenter = null;
        el.onmouseleave = null;
    }
};
