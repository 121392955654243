<template class="screen">
  <test class="side"/>
  <user-navbar class="sideNav"/>
  <sidebar class="sideNav"/>
    <div class="container-fluid col-sm-10 align-item-start" >
      <div class="  ">
        <h1 class="text-start font-brand" style="color: #0D5286">Informations personnelles</h1>
        <h5 class="text-start" style="font-family: Raleway, regular!important; color: #8A92A6; margin: -2% 0 3% 0 "
        >Infos sur vous et vos préférences dans les services SYGALIN.</h5>
      </div>
      <div class="row">
      <div class="col-sm-6" style="margin-left: 0; margin-bottom: 10%;">
        <div class="card bg-white rounded-4">
          <div class="card-body">
            <div class="row col-sm-12">
              <div class="col-sm-12 text-start">
                <p class="font-brand" style="font-size: 25px">Informations générales</p>
                <p style="margin-top: -5%; font-size: 12px; font-family: Raleway, regular!important;"
                >Certaines de ces informations peuvent être vue par les autres utilisateurs des services de SYGALIN</p>
              </div>
              <table style="margin: 3% 0 03% 02%">
                <tr style="margin: 3% 0 0 03%">
                  <td class="text-start" style="font-family: Raleway, regular!important; font-size: 16px;"
                  >Nom :  <b>Lil aïby{{}}</b>
                    <hr style="margin: 0%"/>
                  </td>
                  <td class="text-start" style="font-family: Raleway, regular; font-size: 16px;"
                  >
                    <a href="/edit">
                      <img src="../../assets/img/itemsSidebar/edit.svg" class=""
                           style="height: auto;
                          margin: 0 0 0 0;
                          width: 16px"/>
                    </a>
                    <hr style="margin: 0"/>
                  </td>
                </tr>
                <tr style="margin: 3% 0 0 03%">
                  <td class="text-start" style="font-family: Raleway, regular!important; font-size: 16px;"
                  >Date de naissance:  <b>11 Février 1980{{}}</b>
                    <hr style="margin: 0%"/>
                  </td>
                  <td class="text-start" style="font-family: Raleway, regular; font-size: 16px;"
                  >
                    <a href="/edit">
                      <img src="../../assets/img/itemsSidebar/edit.svg" class=""
                           style="height: auto;
                          margin: 0 0 0 0 ;
                          width: 16px"/>
                    </a>
                    <hr style="margin: 0"/>
                  </td>
                </tr>
                <tr style="margin: 8% 0 0 03%">
                  <td class="text-start" style="
                  font-family: Raleway, regular!important; font-size: 16px;">Genre: <b>Masculin{{}}</b>
                    <hr style="margin: 0"/>
                  </td>
                  <td class="text-start" style="
                  font-family: Raleway, regular!important; font-size: 16px;">

                    <a href="/edit">
                      <img src="../../assets/img/itemsSidebar/edit.svg" class="potision-relative"
                           style="height: auto;
                          margin: 0 0 0 0 ;
                          width: 16px"/>
                    </a>
                    <hr style="margin: 0"/>
                  </td>
                </tr>
              </table>
            </div>
            </div>
        </div>
      </div>
        <div class="col-sm-6" style="margin-left: 0; margin-bottom: 10%;">
        <div class="card bg-white rounded-4">
          <div class="card-body">
            <div class="row col-sm-12">
              <div class="col-sm-12 text-start">
                <p class="font-brand" style="font-size: 25px">Coordonnées</p>
                 </div>
              <table style="margin: 3% 0 03% 02%">>
                <tr style="margin: 8% 0 0 03%">
                  <td class="text-start" style="
                  font-family: Raleway, regular!important; font-size: 16px;">
                    Adresse e-mail : <span><b>{{}}lilaïby@gmail.com</b></span>
                    <hr style="margin: 0%" class="position-relative"/>
                  </td>
                  <td class="text-start" style="font-family: Raleway, regular; font-size: 16px;"
                  >
                    <a href="/edit">
                      <img src="../../assets/img/itemsSidebar/edit.svg" class=""
                           style="height: auto;
                          margin: 0 0 0 0 ;
                          width: 16px"/>
                    </a>
                    <hr style="margin: 0%"/>
                  </td>
                </tr>
                <tr style="margin: 8% 0 0 03%">
                  <td class="text-start" style="
                  font-family: Raleway, regular!important; font-size: 16px;">G
                    Téléphone : <span><b>{{}}656 02 00 38</b></span>
                    <hr style="margin: 0%"/>
                  </td>
                  <td class="text-start" style="font-family: Raleway, regular; font-size: 16px;"
                  >
                    <a href="/edit">
                      <img src="../../assets/img/itemsSidebar/edit.svg" class=""
                           style="height: auto;
                          margin: 0 0 0 0 ;
                          width: 16px"/>
                    </a>
                    <hr style="margin: 0%"/>
                  </td>
                </tr>
                <tr style="margin: 8% 0 0 03%">
                  <td class="text-start" style="
                  font-family: Raleway, regular!important; font-size: 16px;">
                    Pays : <b>Azerbaïdjan{{}}</b>
                    <hr style="margin: 0%"/>
                  </td>
                  <td class="text-start" style="font-family: Raleway, regular; font-size: 16px;"
                  >
                    <a href="/edit">
                      <img src="../../assets/img/itemsSidebar/edit.svg" class=""
                           style="height: auto;
                          margin: 0 0 0 0 ;
                          width: 16px"/>
                    </a>
                    <hr style="margin: 0%"/>
                  </td>
                </tr>
                <tr style="margin: 8% 0 0 03%">
                  <td class="text-start" style="
                  font-family: Raleway, regular!important; font-size: 16px;">Login : <b>Lil_aïby{{}}</b>
                  </td>
                </tr>
              </table>
            </div>
            </div>
        </div>
      </div>
      </div>
      <Footer/>
      <floating-botton/>
    </div>
<!--    <div style="margin: 0% 0 0 -2%">-->
<!--    </div>-->
  </template>

<script>
import UserNavbar from "@/components/UserNavbar.vue";
import Footer from "@/components/Footer.vue";
import FloatingBotton from "@/components/floatingBotton.vue";
import Sidebar from "@/components/SideBar.vue";
import Test from "@/view/signup/responsiveBar.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Data",
  components: {
    Test,
    Sidebar,
    FloatingBotton,
    Footer,
    UserNavbar
  }
}
</script>

<style >
@media screen and (max-width: 1278px) {
  .sideNav{
    display: none !important;
    max-width: 0!important;
  }
  .hide-img{
    display: none !important;
    max-width: 0!important;
  }
  body{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 320px) {
  .sideNav{
    display: none !important;
    max-width: 0!important;
  }
  .hide-img{
    display: none !important;
    max-width: 0!important;
  }
  body{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1278px) {
  .sideNav{
    display: none !important;
    max-width: 0!important;
  }
  .hide-img{
    display: none !important;
    max-width: 0!important;
  }
  body{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>