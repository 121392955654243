<template>
  <div v-if="password !== true" class="container-fluid d-flex justify-content-center align-items-center">
  <div class="col-4 p-4 rounded-5 bg-white responsive shadow ">
    <div class="col-sm-12">
      <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS" class="img-fluid"
           style="max-width: 60px; height: auto; margin-top: -3%;">
      <span class="col-sm-5 font-brand" style="color: #0D5286; margin-left: 0;  font-size: 200%"
      ><b>SYGALIN SAS</b></span>
      <div class="position-relative">
        <div class="col-7">
          <img class="img-fluid dame" src="../../assets/img/femme_violette.svg"
               style="width: 60%;
                 height: auto;
                 margin-left: 125%;
                 margin-top: -60%"
               alt="La dame en qui manipule son ordinateur portable"/>
        </div>
      </div>
    </div>
    <div class="small">
      <h4 class="mb-4 font-brand" style="color: #0C5286;
        font-size: 26px;"><b>Connectez-vous!</b></h4>
      <span class="loader"></span>
      <div style="margin-top: -3%">
        <span class="MTNBrighterSans-Bold-light">Veuillez renseigner votre login!</span>
      </div>
    </div>
    <br>
    <div class="form">
      <form @submit.prevent="handleSubmit">
        <div class="mb-2 row align-items-center justify-content-center">
          <div class="col-sm-7">
            <input type="text" placeholder="Insérez votre login" class="form-control placeholder-bold rounded-pill"
                   id="username" v-model="username" style="--placeholder-color: red ; background-color: #F6F8FA;" @input="typing" required :disabled="isSubmitting">
            <div class="col-sm-12">
              <div class="col-sm-12 text-start">
                <br>
                <p class="">
                  <span class="text-danger" v-if="netError" style="font-size: .9375rem;">
                    Connexion à internet instable. Veuillez réessayer!
                  </span>
                  <span class="text-danger"  style="margin-left: 8px;font-size: .9375rem;" v-if="notFound">Compte introuvable<br></span>
                  <span v-if="usernameError" class="text-danger" style="margin-left: 8px">Le nom d'utilisateur est requis.<br></span>
                </p>
                  <span class="col-sm-7 text-success" style="color: #6B982F;
                  font-size:.9375rem;;
                  font-family: 'MTNBrighterSans', sans-serif;margin-left: 8px">Vous n'avez pas encore de compte ?</span>
                <router-link @click="creatAccount" to="/signup" class="router-link"><br>
                  <span class="mb-4" style="margin-left: 8px;font-size: .9375rem; color: #0D5286"><b>Créer un compte</b></span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="position-absolute">
        <img class="dame" src="../../assets/img/homme_gros.svg" style="
          margin-top: -37%;
          margin-left: -110%;
            width: 50%;
            height: auto;
            padding-top: 50%"
             alt="image du globe"/>
      </div>
        <div class="col-md-12 d-flex justify-content-end" style="margin-top: 20%">
          <button type="submit" class="my-button">
            <span v-if="!isSubmitting" id="button-text">Suivant</span>
            <div v-else class="spinner" id="spinner"></div>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="img-fluid col-sm-5 dame ">
    <img class="img-fluid" src="../../assets/img/Globe.svg"
         style="max-width: 100%; height: auto; margin-left: 10%;" alt="image du globe"/>
  </div>
</div>
  <transition v-if="password === true" name="slide-fade" mode="out-in">
    <signon/>
  </Transition>
</template>
      <script>
      import { baseUrl } from '@/store/baseUrl';
      import { useRoute } from 'vue-router';
      import Signon from "@/view/signin/SignOn.vue";

      export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "signin",
        components: {
          Signon,
        },
        data() {
          return {
            password: false,
            isSubmitting:false,
            notFound: false,
            nextStape:false,
            netError:false,
            show:false,
            isLoading: false,
            username: '',
            usernameError: false
          };
        },
        created() {
          const savedData = localStorage.getItem('username');
          const savedPassword = localStorage.getItem('password');
          if (savedData) {
            this.username = savedData;
          }
          if (savedPassword) {
            this.password = false;
          }
        },
        methods: {
          creatAccount(){
            const route = useRoute()
            this.$router.push({ name: 'signup', query: this.$route.query });
            const redirect = {
              'redirect' : this.$route.query
            }
            localStorage.setItem('redirect',  JSON.stringify(this.$route.query) )
          },
          typing(){
            this.notFound = false;
            this.usernameError = false;
          },
          async handleSubmit() {
            const controller = new AbortController();
            const signal = controller.signal;
            const timeout  = setTimeout(() => {
              controller.abort();
              this.isSubmitting = false;
              this.netError = true
            }, 30000);
            try {
              this.netError = false;
              this.notFound = false
              if (!this.username.trim()) {
                this.usernameError = true;
              } else {
                this.usernameError = false;
                localStorage.setItem('username', this.username);
                const data = {
                  username: this.username,
                };
                this.isSubmitting = true;
               // console.log("base environnement : ", process.env,'url : ',process.env.BASE_URL , 'Variable admin : ', )
                const response = await fetch(`${baseUrl}/auth/v1/challenge/username/` ,
                    {
                      signal,
                      method: 'post',
                      headers: {
                        // 'Authorization': 'tokken',
                        'Content-Type': 'application/json',
                        // "Access-Control-Allow-Origin": "*",
                      },
                      body: JSON.stringify(data)
                      // signal: signal
                    });
                this.respond = await response.json();
                if (this.respond !== null){
                  clearTimeout(timeout);
                }
                if (this.respond.status === 404){
                  this.notFound = true;
                }
                if (this.respond.status === 200){
                  this.isSubmitting = false;
                  const route = useRoute()
                  this.$router.push({ name: 'SignOn', query: this.$route.query });
                  // this.password = true;
                  localStorage.setItem('password', this.password);
                  // this.$router.push('/signon');
                }
                this.isSubmitting = false;
                // console.log(this.respond);
              }
            }catch (e) {
              this.isSubmitting = false;
              this.netError = true;
            }
          },
        mounted() {
          localStorage.removeItem('username');
        }
      }
      };
      </script>


      <style>
      .MTNBrighterSans-Bold-Sygalin {
        font-family: MTNBrighterSans, sans-serif;
        font-size: .9375rem;
        color: #0E5386;
      }

      .placeholder-bold::placeholder {
        font-weight: bold;
        font-size: .9375rem;;
        color: #a3a9b7;
      }
      .MTNBrighterSans-Bold {
        font-family: MTNBrighterSans-Bold, sans-serif;
      }

      .MTNBrighterSans-Bold-Sygalin {
        font-family: MTNBrighterSans, sans-serif;
        font-size: 13px;
        color: #545988;
      }
      .MTNBrighterSans-Bold-ligh {
        font-family: MTNBrighterSans,sans-serif;
        font-size: 12px;
        color: #C2C7D1;
        margin-top: -30px;
      }

      .my-button:hover {
        background-color: #0056b3;
      }

      .router-link {
        text-decoration: none;
      }
      .fade-enter-from,
      .fade-leave-to {
        opacity: 0;
      }
      .fade-enter-active,
      .fade-leave-active{
        transition: opacity 0.5s ease-out;
      }
      ::placeholder {
        color: #0D5286!important;
        opacity: 0.5 !important;
      }
      .slide-fade-enter-active {
        transition: all 0.3s ease-out;
      }

      @media (min-width: 177px) and (max-width: 768px) {
        .dame {
          display: none !important;
        }
        .responsive {
          width: 80% !important;
        }
      }

      .slide-fade-enter-active {
        transition: all 0.3s ease-out;
      }

      .slide-fade-leave-active {
        transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
      }

      .slide-fade-enter-from,
      .slide-fade-leave-to {
        transform: translateX(-20px);
        opacity: 0;
      }

      /*
            création du second loader
            .loader {
            //  width: 48px;
            //  height: 48px;
            //  border-radius: 50%;
            //  display: inline-block;
            //  border-top: 4px solid #FFF;
            //  border-right: 4px solid transparent;
            //  box-sizing: border-box;
            //  animation: rotation 1s linear infinite;
            //}
            //.loader::after {
            //  content: '';
            //  box-sizing: border-box;
            //  position: absolute;
            //  left: 0;
            //  top: 0;
            //  width: 48px;
            //  height: 48px;
            //  border-radius: 50%;
            //  border-left: 4px solid #FF3D00;
            //  border-bottom: 4px solid transparent;
            //  animation: rotation 0.5s linear infinite reverse;
            //}
            //@keyframes rotation {
            //  0% {
            //    transform: rotate(0deg);
            //  }
            //  100% {
            //    transform: rotate(360deg);
            //  }
            //}*/
      </style>
