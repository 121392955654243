<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="../assets/img/logo.png" alt="logo SYGALIN SAS"
             class="img-fluid position-relative " style="max-width: 50px; height:auto; margin: 5% 0 0 0; padding-top:-10% ">
        <a class="router-link font-brand" style="margin: -20% 0 0 0 ; font-size: 15px;color: #134973;" href="#">Sygalin
        </a>
        <p class="font-brand" style="font-size: 15px; margin: -16% 0 0 50%;color: #134973;"> Market</p>
      </a>
      <form class="form-inline my-2 my-lg-0">
        <input class="form-control position-relative mr-sm-2" type="search"
               style="margin-left: -0%" placeholder="Rechercher" aria-label="Search"/>
        <div style="margin-top: -12%; margin-left: 87%">
          <n class="fas fa-search position-relative" style="font-size: 15px;"></n>
        </div>
      </form>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse flex justify-between"  id="navbarSupportedContent"
           style="flex-grow: revert ">
        <ul class="navbar-nav me-auto mb-3 mb-lg-0">
          <li class="nav-item font-brand" style="margin-left: -10%">
            <router-link to="/signin" class="router-link" style="color: #134973;
              font-size: 17px;">Se connecter</router-link>
          </li>
          <li class="nav-item font-brand" style="margin-left:10%">
            <router-link to="/signup" class="router-link" style="font-size: 17px;
              color: #16BB37;">S'inscrire
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Navbar',
};
</script>

<style scoped>
@media screen max-width(300px) {
  nav-item{
    margin: 0 !important;
  }
}

</style>
