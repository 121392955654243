<template>
  <div class="container-fluid col-sm-11 d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounde bg-white shadow "  >
      <img src="../../../assets/img/logo.png" alt="logo SYGALIN SAS" class="img-fluid"
           style="max-width: 60px; height: auto; margin-top: -3%;">
      <span class="col-sm-5" style="color: #0D5286; margin-left: -3%; margin-top: 3%; font-size: 200%"
      ><b>SYGALIN SAS</b></span>
      <div class="position-absolute">
        <img class="" src="../../../assets/img/dame_en_veste_jaune.svg" style="
          margin-top: -70%;
          margin-left: -160%;
            top: 652px;
            left: 247px;
            width: 274px;
            height: 268px;
            padding-left: 65%"
             alt="image du globe"/>
      </div>
      <br/><br/>
      <br/>
      <div class="small">
        <h4 class="mb-4 font-brand" style="color: #8EBE45"><b>Votre mot de passe<br/>a étét réinitialisé<br/>avec succes!</b></h4>
        <br/><p class="mb-4 font-brand"
          style="font-size: 17px">Vous pouvez jouir de toute l'expérience</p>
        <p class="font-brand" style="margin-top: -20px; ">SYGALIN</p>
      </div>
      <router-link to="/signin" class="router-link">
        <p class="p"></p> <button class="my-button font-brand">Accéder à<br/>My SYGALIN</button>
      </router-link>
      </div>
    <div class="img-fluid col-sm-7 d-none d-sm-none d-md-block d-lg-block" style="margin-top: 3%; margin-left: 30px;">
      <img class="img-fluid" src="../../../assets/img/victoria.svg"
           style="max-width: 80%; height: auto;" alt="image d'un homme qui monte un échelle"/>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "complet"
}
</script>

<style scoped>
.router-link {
  text-decoration: none;
}
.p{
  font-size: 15px;
  color: #1c7bc5;
}
.o{
  font-family: MTNBrighterSans, sans-serif;
  font-size: 15px;
}
</style>